import { createReducer, on } from '@ngrx/store';
import { ContentCard } from '../../models';
import * as pageActions from '../actions/content-card.action';

export interface ContentCardsState {
  entities: { [id: number]: ContentCard };
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: ContentCardsState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const ContentCardsReducer = createReducer(
  initialState,
  on(pageActions.LoadContentCard, (state: ContentCardsState, { id }) => {
    return {
      ...state,
      loaded: false,
      loading: true,
    };
  }),
  on(pageActions.LoadContentCardSuccess, (state: ContentCardsState, { slider }) => {
    const entities = {
      ...state.entities,
      [slider.id]: slider,
    };
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.LoadContentCardFail, (state: ContentCardsState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getContentCardsEntities = (state: ContentCardsState) => state.entities;
export const getContentCardsLoading = (state: ContentCardsState) => state.loading;
export const getContentCardsLoaded = (state: ContentCardsState) => state.loaded;
