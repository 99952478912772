import { createSelector } from '@ngrx/store';
import { BaseContent } from 'src/app/content/models';
import { LandingPage } from 'src/app/landing-pages/models';
import { SearchResult, ContentType } from '../../models';
import * as fromFeature from '../reducers';
import * as fromSearchResults from '../reducers/search-results.reducer';

export const getSearchResultState = createSelector(fromFeature.getSearchState, (state: fromFeature.SearchState) => state.results);

export const getSearchResultEntities = createSelector(getSearchResultState, fromSearchResults.getSearchResultEntities);
export const getSearchResultsLoaded = createSelector(getSearchResultState, fromSearchResults.getSearchResultsLoaded);
export const getSearchResultsLoading = createSelector(getSearchResultState, fromSearchResults.getSearchResultsLoading);

export const getSearchPatchQuery = createSelector(getSearchResultState, fromSearchResults.getSearchPatchQuery);

export const getAllSearchResults = createSelector(getSearchResultEntities, entities => {
  return Object.keys(entities).map(id => entities[id as unknown as number]);
});

export const getOrderedSearchResults = createSelector(getAllSearchResults, (results: SearchResult<BaseContent | LandingPage>[]) => {
  const orderedResults = groupBy(results.sort((a, b) => b.relevance - a.relevance), a => a.type);
  const type: ContentType = "Activiteit";

  if (orderedResults[type]) {
    orderedResults[type] = orderedResults[type]
    .filter((a: any) => new Date(a.reference?.end || a.reference?.start) > new Date(Date.now()))
    .sort((a: any, b: any) => (new Date(a.reference?.start) > new Date(b.reference?.start) ? 1 : -1));
  }

  return orderedResults;
});

function groupBy<T>(arr: T[], fn: (item: T) => any) {
  return arr.reduce<Record<string, T[]>>((prev, curr) => {
    const groupKey = fn(curr);
    const group = prev[groupKey] || [];
    group.push(curr);
    return { ...prev, [groupKey]: group };
  }, {});
}
