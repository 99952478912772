import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContentCard } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ContentCardService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}
  loadContentCard(id: number) {
    return this.http.get<ContentCard>(`${this.BASE_URL}api/content-card/${id}`);
  }
}
