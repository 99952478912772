import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  InjectionToken,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, UntypedFormGroup } from '@angular/forms';
import { GoogleMap, MapGeocoder } from '@angular/google-maps';
import { DynamicFormField } from '@teamfoster/sdk/legacy-dynamic-form';
import { catchError, debounceTime, map, Observable, of, Subject, take, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Geolocation } from '../../../core/models';

@Component({
  selector: 'app-form-location',
  templateUrl: './form-location.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormLocationComponent implements OnInit {
  config!: DynamicFormField;
  group!: UntypedFormGroup;

  apiLoaded: Observable<boolean> = of(false);

  options: google.maps.MapOptions = {
    center: { lat: 52.2739187, lng: 5.969158 },
    zoom: 9,
  };

  searchForm = this.fb.group({
    query: [''],
  });

  @ViewChild('maps') maps?: GoogleMap;

  markerOptions: google.maps.MarkerOptions = { draggable: true };
  marker?: google.maps.LatLngLiteral | google.maps.LatLng;
  locationLabel?: string;

  locationResults: google.maps.GeocoderResult[] = [];
  private _unsubscribe$ = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private geocoder: MapGeocoder,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (window['google']?.['maps']) {
        this.apiLoaded = of(true);
      } else {
        this.apiLoaded = this.httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${environment.mapsApiKey}`, 'callback').pipe(
          map(() => true),
          catchError(e => {
            console.error('Google map not loaded', e);
            return of(false);
          })
        );
      }
    }

    if (this.config.value) {
      const val: Geolocation = this.config.value;

      if (val) {
        this.group.get(this.config.name)?.patchValue(val);

        this.marker = {
          lat: val?.lat,
          lng: val?.lng,
        };
        this.locationLabel = val?.label;

        this.searchForm.get('query')?.patchValue(val?.label, { emitEvent: false, onlySelf: true });

        this.cd.detectChanges();
      }
    }

    this.searchForm
      .get('query')
      ?.valueChanges.pipe(debounceTime(200), takeUntil(this._unsubscribe$))
      .subscribe((val: any) => {
        this.getLocationFromString(val?.['formatted_address'] || val);
      });
  }

  // from clickevents of map
  getLocation(location?: google.maps.LatLng | google.maps.LatLngLiteral, setFirstResult: boolean = false) {
    if (!location) {
      return;
    }

    this.geocoder
      .geocode({
        location: location,
      })
      .pipe(take(1))
      .subscribe(({ results }) => {
        this.locationResults = results;

        if (setFirstResult && results.length) {
          this.locationLabel = results[0].address_components.filter(ac => ~ac.types.indexOf('locality'))[0].long_name; // get only city name

          this.searchForm.get('query')?.patchValue(this.locationLabel, { emitEvent: false, onlySelf: true });
          this.updateFormValue();
        }
        this.cd.detectChanges();
      });
  }

  // chose one of autocomplete options
  selectLocationFromResults(location: google.maps.GeocoderResult) {
    const result = location.geometry.location.toJSON();

    if (location) {
      this.marker = result;
      this.locationLabel = location.formatted_address;
      this.searchForm.get('query')?.patchValue(this.locationLabel, { emitEvent: false, onlySelf: true });

      this.maps?.googleMap?.setCenter(result);

      this.updateFormValue();
      this.cd.detectChanges();
    }
  }

  // on searchform change
  getLocationFromString(query?: string | null) {
    const q = query || this.searchForm.get('query')?.value;

    if (q) {
      this.geocoder
        .geocode({
          address: q,
        })
        .pipe(take(1))
        .subscribe(({ results }) => {
          this.locationResults = results;

          this.cd.detectChanges();
          //this.geoCodeResult(results);
        });
    }
  }

  // mapDragend
  // mapClick
  setMarker(event: google.maps.MapMouseEvent) {
    const coords = event.latLng?.toJSON();

    if (coords) {
      this.marker = coords;
      this.getLocation(coords, true);
      this.updateFormValue();
    }
  }

  updateFormValue() {
    const field = this.group.get(this.config.name);

    field?.setValue({
      lat: this.marker?.lat || this.config.value?.lat,
      lng: this.marker?.lng || this.config.value?.lng,
      label: this.locationLabel || this.config.value?.label,
    });
  }

  displayFn(result: google.maps.GeocoderResult | string): string {
    if ((<google.maps.GeocoderResult>result).hasOwnProperty('formatted_address')) {
      return (<google.maps.GeocoderResult>result)?.formatted_address;
    }
    return <string>result;
  }

  isRequired(field: AbstractControl<any, any> | null) {
    if (!field || !field.validator) {
      return false;
    }
    const validator = field?.validator({} as AbstractControl);
    return validator && validator['required'];
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
