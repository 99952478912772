import * as fromLikes from './likes.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'Likes';

export interface RootLikesState {
  likes: fromLikes.LikesState;
}

export const reducers: ActionReducerMap<RootLikesState> = {
  likes: fromLikes.LikesReducer,
};

export const getRootLikesState = createFeatureSelector<RootLikesState>(featureKey);
export const metaReducers: MetaReducer<RootLikesState>[] = !environment.production ? [] : [];
