import { isPlatformServer } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, InjectionToken, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';
import { LoadSearchResults } from '../search/store';
import { OpenMenu, OpenSearch } from '../store';

@Component({
  selector: 'app-404',
  templateUrl: './404.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent implements OnInit {
  searchControl = new FormControl('', []);
  code = 404;

  constructor(
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>,
    @Optional() @Inject(RESPONSE) private response: Response,
    private router: Router,
    private store: Store
  ) {
    const statusCodeFromUrl = parseInt(this.router.url.replace(/\//g, ''));
    this.code = statusCodeFromUrl ? statusCodeFromUrl : 404;
  }

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      if (this.response) {
        this.response.status(this.code);
      }
    }
  }

  searchValueChange(q: string) {
    if (!q) {
      return;
    }

    this.store.dispatch(OpenSearch());
    this.store.dispatch(LoadSearchResults({ q, patch: true }));
  }
}
