import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { Story } from 'src/app/stories/models';

@Component({
  selector: 'app-related-stories',
  templateUrl: './related-stories.component.html',
  styleUrls: ['./related-stories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelatedStoriesComponent implements OnInit {
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() stories!: Story[] | null;

  sliderConfig = {
    showBullets: false,
    showArrows: false,
    type: 'slider',
    bound: true,
    rewind: true,
    perView: 3,
    breakpoints: {
      1200: {
        perView: 3,
      },
      991: {
        perView: 2,
      },
      600: {
        perView: 1,
      },
      390: {
        perView: 1,
      },
    },
  };

  private dict = new FromDictionaryPipe(this.lang);

  constructor(private lang: LanguageService) {}

  ngOnInit(): void {}
}
