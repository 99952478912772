<div class="row p-0">
  <div class="mt-5">
    <h2 class="text-center text-primary">
      {{ 'error-404-title' | fromDictionary }}

      <app-icon matSuffix anchor="icon-squiggle" class="heading-squiggle mt-1"></app-icon>
    </h2>
    <div class="mx-auto bg-gray-100 col-1"></div>
    <p class="text-primary mx-auto mt-2 pt-4 col-10 col-sm-9 col-md-5 col-lg-5 text-center">
      {{ 'error-404-text' | fromDictionary }}
    </p>
  </div>

  <div class="mx-auto mt-4 mb-2 col-10 col-sm-8 col-sm-7 col-md-8 col-lg-5">
    <app-search-bar (valueChanges)="searchValueChange($event)" slot="search-bar"></app-search-bar>
  </div>
</div>
