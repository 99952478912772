import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnInit {
  @Input() anchor!: string;
  @Input() title: string = 'icon';
  @Input() viewbox: string = '';
  @Input() ariaHidden: boolean = true;

  constructor() {}

  ngOnInit(): void {
    // warn user when icon is not properly set
    if (!this.anchor) {
      console.warn('icon anchor is required');
    }
  }
}
