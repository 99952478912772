import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CookiePreferences } from '@teamfoster/sdk/cookie-ngrx';
import { Header } from '../../models';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  @Input() title?: string;
  @Input() header!: Header;
  @Input() themeDark: boolean = false;
  @Input() logoAsTitle: boolean = false;
  @Input() cookiePreferences!: CookiePreferences | null;
  @Input() hasWave = false;

  @Output() inView = new EventEmitter<any>();
  inviewPort = false;

  constructor() {}

  inViewChange(e: boolean) {
    this.inviewPort = e;
    if (e) {
      this.inView.emit(null);
    }
  }
}
