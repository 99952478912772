<section class="wave-cta mt-2" [class.is--big-title]="bigTitle">
  <div class="container wave-cta__inner">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xml:space="preserve"
      id="Layer_1"
      x="0"
      y="0"
      style="enable-background: new 0 0 957 209.4"
      version="1.1"
      viewBox="0 0 957 209.4"
    >
      <defs>
        <clipPath id="a">
          <path
            d="M957 290.35v-180c-79.54 0-123.59 29.46-155.76 50.97-27.07 18.1-43.41 29.03-83.49 29.03s-56.42-10.93-83.49-29.03c-32.17-21.51-76.21-50.97-155.76-50.97s-123.59 29.46-155.76 50.97c-27.07 18.1-43.41 29.03-83.49 29.03s-56.42-10.93-83.49-29.03C123.59 139.81 79.54 110.35 0 110.35v180h957Z"
            style="fill: none"
          />
        </clipPath>
      </defs>

      <g>
        <!-- Multiline -->
        <text transform="translate(.4611 90)" class="wave-cta__text" *ngIf="!bigTitle">
          <tspan x="0" y="0" class="st2 st3 st4">{{ firstLine || ('footer-cta-regel-1' | fromDictionary) }}</tspan>
          <tspan x="0" y="60" class="st2 st3 st4">{{ secondLine || ('footer-cta-regel-2' | fromDictionary) }}</tspan>
        </text>

        <!-- Single line -->
        <text style="font-family: Alberto-Regular, Alberto; font-size: 260px; fill: #0f729c" transform="translate(340.5 193.08)">
          <tspan x="0" y="0">{{ bigTitle }}</tspan>
        </text>
      </g>

      <path
        d="M957 290.35v-180c-79.54 0-123.59 29.46-155.76 50.97-27.07 18.1-43.41 29.03-83.49 29.03s-56.42-10.93-83.49-29.03c-32.17-21.51-76.21-50.97-155.76-50.97s-123.59 29.46-155.76 50.97c-27.07 18.1-43.41 29.03-83.49 29.03s-56.42-10.93-83.49-29.03C123.59 139.81 79.54 110.35 0 110.35v180h957Z"
        style="fill: #04e5dc"
      />

      <g style="clip-path: url(#a)">
        <text style="fill: #fff; font-family: Alberto-Regular, Alberto; font-size: 260px" transform="translate(340.5 193.08)">
          <tspan x="0" y="0">{{ bigTitle }}</tspan>
        </text>

        <text transform="translate(.3956 90)" class="wave-cta__text" style="fill: #fff" *ngIf="!bigTitle">
          <tspan x="0" y="0" class="st3 st4">{{ firstLine || ('footer-cta-regel-1' | fromDictionary) }}</tspan>
          <tspan x="0" y="60" class="st3 st4">{{ secondLine || ('footer-cta-regel-2' | fromDictionary) }}</tspan>
        </text>
      </g>
    </svg>
  </div>
</section>
