import { createAction, props } from '@ngrx/store';
import { BaseContent } from 'src/app/content/models';
import { LandingPage } from 'src/app/landing-pages/models';
import { SearchResult } from '../../models';

export const LoadSearchResults = createAction('[Search] Load Search Results', props<{ q: string; patch?: boolean }>());
export const LoadSearchResultsSuccess = createAction(
  '[Search] Load Search Results Success',
  props<{ results: SearchResult<BaseContent | LandingPage>[]; patch?: boolean }>()
);
export const LoadSearchResultsFail = createAction('[Search] Load Search Results Fail', props<{ error: any }>());
