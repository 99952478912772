import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-grid-overlay',
  templateUrl: './grid-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridOverlayComponent {
  gridColumns = [...Array(12).keys()];

  constructor() {}
}
