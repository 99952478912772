<div class="grid-overlay">
  <div class="container">
    <div class="row grid-showcase">
      <div *ngFor="let number of gridColumns" class="col-1">
        <div class="grid-showcase__inner">
          {{ number + 1 }}
        </div>
      </div>
    </div>
  </div>
</div>