<app-auth-layout [title]="prefix + '-titel' | fromDictionary">
  <ng-container form>
    <app-alert
      class="d-block mt-4 mb-4"
      *ngIf="registerError$ | async as errors"
      [status]="'danger'"
      [title]="prefix + 'formulier-error-titel' | fromDictionary"
    >
      <ng-container>
        <ul *ngIf="errors?.error?.errors.length > 1">
          <li class="" *ngFor="let error of errors?.error?.errors">
            {{ error.description }}
          </li>
        </ul>
        <div *ngIf="errors?.error?.errors.length < 2">
          <p class="mb-0" *ngFor="let error of errors?.error?.errors">
            {{ error.description }}
          </p>
        </div>
      </ng-container>
    </app-alert>

    <fstr-dynamic-form
      *ngIf="(registerSuccess$ | async) === false"
      [config]="formFields"
      [appearance]="'fill'"
      (formSubmit)="handleSubmit($event)"
      [showSubmit]="false"
      class="theme-light"
      #form
    >
      <div slot="form-actions">
        <button type="submit" class="button button--primary">
          {{ prefix + '-knoptekst' | fromDictionary }}
        </button>
      </div>
    </fstr-dynamic-form>

    <article class="card d-block bg-success text-white" *ngIf="registerSuccess$ | async">
      <h2 class="h4 font-secondary fw-bold mb-3">{{ prefix + '-formulier-succes-geslaagd-titel' | fromDictionary }}</h2>
      <p>{{ prefix + '-formulier-succes-geslaagd-tekst-tmp' | fromDictionary }}</p>
      <button type="button" [routerLink]="['/']" class="button mt-3 button--light button--sm text-success">
        {{ prefix + '-terug-naar-home-knoptekst' | fromDictionary }}
      </button>
    </article>
  </ng-container>
</app-auth-layout>
