<article class="card story-teaser-card p-0" [class.set--inverted]="inverted">
  <a [routerLink]="linkToContent" class="d-flex block-link p-2">
    <header class="story-teaser-card__header col-12 col-lg-7">
      <figure class="story-teaser-card__figure">
        <app-image
          [alt]="image.caption || title"
          [image]="image"
          [sizes]="{ md: 4, xl: 3 }"
          [imageSizes]="[300, 500, 800]"
          [width]="700"
          [height]="500"
        ></app-image>
      </figure>
      <div class="story-teaser-card__wrapper text-light">
        <strong class="text-secondary story-teaser-card__subtitle" *ngIf="subtitle">{{ subtitle | truncate : 50 }}</strong>
        <h2 class="type-body-md fw-bold font-secondary story-teaser-card__title mb-0">{{ title }}</h2>
      </div>
    </header>
    <footer class="col-lg-5 story-teaser-card__footer">
      <a [routerLink]="linkToContent" class="story-teaser-card__footer__link">
        <span class="h3 text-center mb-0">{{ linkText }}</span>
        <span class="button button-icon button--md button--tertiary">
          <app-icon anchor="icon-arrow-right"></app-icon>
        </span>
      </a>
    </footer>
  </a>
</article>
