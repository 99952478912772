import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromStore from './store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../core/core.module';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';

const moduleExports = [SearchBarComponent, SearchResultsComponent];
@NgModule({
  declarations: [...moduleExports],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers),
    EffectsModule.forFeature(fromStore.effects),
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    RouterModule,
    CoreModule,
    DictionaryNgrxModule,
  ],
  exports: [...moduleExports],
})
export class SearchModule {}
