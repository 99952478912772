<div
  class="custom-field form-location"
  *ngIf="group.get(config.name) as field"
  [class.has--label]="!!config.label"
  [class.has--sub-label]="!!config.subLabel"
  [ngClass]="{
    'custom-field--invalid': field.invalid && (field.touched || field.dirty)
  }"
>
  <ng-container [formGroup]="group">
    <mat-form-field class="form-location__hidden-formfield">
      <input
        matInput
        [ngClass]="{
          'custom-field--invalid': field.invalid && (field.touched || field.dirty)
        }"
        [formControlName]="config.name"
        [hidden]="true"
      />
    </mat-form-field>
  </ng-container>

  <span class="label">
    {{ config.label }}
    <span class="mat-form-field-required-marker" *ngIf="isRequired(group.get(config.name))">*</span>
  </span>

  <div class="custom-field__field">
    <form [formGroup]="searchForm">
      <mat-form-field class="form-location__field" appearance="fill">
        <input type="text" matInput formControlName="query" [matAutocomplete]="auto" [placeholder]="config.placeholder || ''" />

        <app-icon anchor="icon-search" matSuffix></app-icon>

        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let option of locationResults" [value]="option" (click)="selectLocationFromResults(option)">
            {{ option.formatted_address }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>

    <div *ngIf="apiLoaded | async">
      <google-map #maps width="100%" [options]="options" (mapClick)="setMarker($event)">
        <map-marker *ngIf="marker" [position]="marker" [options]="markerOptions" (mapDragend)="setMarker($event)"></map-marker>
      </google-map>
    </div>

    <!--<strong *ngIf="group.get(config.name)?.value as location">{{ location | json }}</strong>-->
  </div>

  <div class="custom-field__error-wrapper">
    <mat-error *ngIf="field.invalid && field.dirty">
      {{ field | formError : config.customValidationMessages }}
    </mat-error>
  </div>
</div>
