import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Event } from 'src/app/events/models';
import { getDistance } from 'geolib';
import { LanguageService } from '@teamfoster/sdk/dictionary-ngrx';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventCardComponent {
  @Input() event!: Event;
  @Input() location!: GeolocationPosition | undefined | null;
  @Input() inverted = false;
  @Input() showIntro = true;
  @Input() label?: string;

  constructor(private lang: LanguageService) {}

  getDistance() {
    if (!this.location || !this.event.location?.lat || !this.event.location?.lng) {
      return undefined;
    }
    return getDistance([this.event.location.lat, this.event.location.lng], [this.location.coords.latitude, this.location.coords.longitude]);
  }

  getMonth(d: Date | string) {
    let date = typeof d === 'string' ? new Date(d) : d;
    return date.toLocaleString(this.lang.getLanguage() || 'nl-NL', { month: 'short' });
  }
}
