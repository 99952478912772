import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromNav from '../reducers/nav.reducer';

export const getNavState = createSelector(fromFeature.getNavState, (state: fromNav.NavState) => state);

export const getMenuOpen = createSelector(getNavState, (state: fromNav.NavState) => state.menuOpen);
export const getTheme = createSelector(getNavState, (state: fromNav.NavState) => state.theme);
export const getLogoHide = createSelector(getNavState, (state: fromNav.NavState) => state.hideLogo);

export const getSearchActive = createSelector(getNavState, (state: fromNav.NavState) => state.searchActive);
