import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContentPage } from 'src/app/content/models';
import { Image } from 'src/app/core/models';

@Component({
  selector: 'app-teaser-card',
  templateUrl: './teaser-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeaserCardComponent {
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() image!: Image;
  @Input() linkToContent!: string | string[];
  @Input() linkText: string = 'Lees meer';
  @Input() inverted = false;
  //@Input() content!: Initiative | Story | ContentPage;
}
