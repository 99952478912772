import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import * as fromLikes from '../reducers/likes.reducer';
import * as fromRoot from '../../../store';

export const getLikesState = createSelector(fromFeature.getRootLikesState, (state: fromFeature.RootLikesState) => state.likes);

export const getLikeEntities = createSelector(getLikesState, fromLikes.getLikesEntities);

export const getLikesLoaded = createSelector(getLikesState, fromLikes.getLikesLoaded);
export const getLikesLoading = createSelector(getLikesState, fromLikes.getLikesLoading);
export const getLikesError = createSelector(getLikesState, fromLikes.getLikesError);
export const getMyLikes = createSelector(getLikesState, fromLikes.getMyLikes);
export const getLikesInitialized = createSelector(getLikesState, fromLikes.getLikesInitialized);

export const getSelectedContentLikes = createSelector(getLikeEntities, fromRoot.getRouterState, (entities, routerState) => {
  const contentId =
    routerState.state?.params &&
    +(routerState.state?.params['initiativeId'] || routerState.state?.params['storyId'] || routerState.state?.params['eventId']);
  return entities[contentId] ?? 0;
});

export const getSelectedContentLiked = createSelector(getMyLikes, fromRoot.getRouterState, (likes, routerState) => {
  const contentId =
    routerState.state?.params &&
    +(routerState.state?.params['initiativeId'] || routerState.state?.params['storyId'] || routerState.state?.params['eventId']);
  return likes?.indexOf(contentId) >= 0 || false;
});
