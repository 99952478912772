<app-wave-cta [bigTitle]="showStatusCode ? '404' : undefined"></app-wave-cta>
<footer class="footer">
  <div class="container section-inset-b">
    <div class="row">
      <div class="col-md-4 my-4 my-md-3">
        <section>
          <span class="text-secondary-overlay fw-bold">{{ 'footer-over-ons-titel' | fromDictionary }}</span>
          <p class="pt-3 type-body-xs" [innerHTML]="'footer-over-ons-tekst' | fromDictionary"></p>
          <a class="button button button--light mt-3" [routerLink]="['/over-ons']">
            {{ 'footer-over-ons-lees-meer-knoptekst' | fromDictionary }}
          </a>
        </section>
      </div>
      <nav class="col-md-3 offset-md-1 my-4 my-md-3">
        <span class="text-secondary-overlay fw-bold">{{ 'footer-snelle-links-titel' | fromDictionary }}</span>
        <ul class="footer__menu-list">
          <li class="footer__menu-list__item pt-3" *ngFor="let item of footerNav; trackBy: trackByFn">
            <a [routerLink]="item.url">
              {{ item?.title }}
            </a>
          </li>
        </ul>
      </nav>

      <!-- start contactinfo -->
      <div class="col-md-3 my-4 my-md-3 offset-md-1">
        <span class="text-secondary-overlay fw-bold">{{ 'footer-contact-titel' | fromDictionary }}</span>
        <ul>
          <li class="d-flex pt-3 align-items-center">
            <em class="chip chip--light-secondary flex-shrink-0">
              <app-icon anchor="icon-mail"></app-icon>
            </em>
            <span class="d-block ps-3" [innerHTML]="'footer-contact-emailadres' | fromDictionary"></span>
          </li>

          <li class="d-flex pt-3 align-items-center" *ngFor="let item of socialChannels">
            <em class="chip chip--light-secondary flex-shrink-0">
              <app-icon [anchor]="item.icon || 'icon-arrow-right'"></app-icon>
            </em>
            <span class="d-block ps-3">
              <a [href]="item.url" target="_blank">{{ item.title }}</a>
            </span>
          </li>
        </ul>
      </div>
      <!-- end contactinfo -->

      <app-json-ld [json]="schema"></app-json-ld>
      <!-- start socials -->
      <div class="col-md-7 col-lg-5 mb-3 offset-md-5">
        <app-actions-card
          class="mb-3 d-block"
          [image]="{ url: 'ambassadeur-afbeelding-url' | fromDictionary }"
          [altText]="'ambassadeur-afbeelding-alt-tekst' | fromDictionary"
          [text]="'ambassadeur-cta-tekst' | fromDictionary"
          [title]="'ambassadeur-cta-titel' | fromDictionary"
          [link]="'ambassadeur-cta-link' | fromDictionary"
        ></app-actions-card>
        <!-- <app-actions-card
          [text]="'maak-een-melding-cta-tekst' | fromDictionary"
          [title]="'maak-een-melding-cta-titel' | fromDictionary"
          [link]="'maak-een-melding-cta-link' | fromDictionary"
        ></app-actions-card> -->
        <!-- <div class="mt-4 footer__socials">
          <span class="fw-bold text-secondary-overlay me-2">{{ 'footer-socials-titel' | fromDictionary }}</span>
          <ul class="d-flex">
            <li class="ms-2" *ngFor="let item of socialChannels">
              <a class="button button--sm button-icon button--light-secondary" [href]="item.url" target="_blank">
                <span class="sr-only">{{ item.title }}</span>
                <img [src]="item.imageUrl || 'placeholder-image' | fromDictionary" [alt]="item.title" *ngIf="item.imageUrl" />
                <app-icon *ngIf="!item.imageUrl && item.icon" [anchor]="item.icon" [title]="item.title"></app-icon>
              </a>
            </li>
          </ul>
        </div> -->
      </div>
      <!-- end socials -->
    </div>
  </div>
  <ul class="footer__sub py-3">
    <li class="ms-0 pt-3 pt-md-0 ms-sm-4">{{ year }} &copy; {{ 'meta-titel-suffix' | fromDictionary }}</li>
    <li class="ms-0 pt-3 pt-md-0 ms-sm-4">
      <button (click)="openCookie()" class="button-link">{{ 'cookie-settings' | fromDictionary }}</button>
    </li>
    <li class="ms-0 pt-3 pt-md-0 ms-sm-4">
      <button *ngIf="isSignedIn" (click)="signOut()">Uitloggen</button>
      <a *ngIf="!isSignedIn" [routerLink]="['/account/inloggen']" class="text-decoration-none">Inloggen</a>
    </li>
  </ul>
</footer>
