import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/search-results.action';
import * as utility from '../../../utility';
import { SearchResult } from '../../models';
import { BaseContent } from 'src/app/content/models';
import { LandingPage } from 'src/app/landing-pages/models';

export interface SearchResultsState {
  entities: { [id: number]: SearchResult<BaseContent | LandingPage> };
  loaded: boolean;
  loading: boolean;
  error?: any;
  patch?: string;
}

export const initialState: SearchResultsState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const searchResultsReducer = createReducer(
  initialState,
  on(actions.LoadSearchResults, (state: SearchResultsState, { q, patch }) => {
    return {
      ...state,
      loading: true,
      patch: patch ? q : undefined,
    };
  }),
  on(actions.LoadSearchResultsSuccess, (state: SearchResultsState, { results }) => {
    const entities = utility.ToEntities(results, 'id', {});
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(actions.LoadSearchResultsFail, (state: SearchResultsState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getSearchResultEntities = (state: SearchResultsState) => state.entities;
export const getSearchResultsLoading = (state: SearchResultsState) => state.loading;
export const getSearchResultsLoaded = (state: SearchResultsState) => state.loaded;
export const getSearchPatchQuery = (state: SearchResultsState) => state.patch;
