<img
  #img
  [alt]="image.alt ?? image.caption ?? alt"
  [src]="image.url || ('placeholder-image' | fromDictionary)"
  [srcset]="srcSetAttr"
  [sizes]="sizesAttr"
  [attr.loading]="loading"
  (load)="loaded.emit(true)"
  [ngClass]="objectFitMode ? 'object-position--' + image.anchor : ''"
  [class.object-fit]="objectFitMode"
/>

<!-- Alleen omhoog schalen update. Is de hoogste al ingeladen blijft deze actief -->
<table *ngIf="debug">
  <tr>
    <td class="pe-4"><strong>currentSrc</strong></td>
    <td>{{ currentSrc }}</td>
  </tr>
  <tr>
    <td class="pe-4"><strong>Aspect</strong></td>
    <td>{{ aspectRatio }}</td>
  </tr>
  <tr>
    <td class="pe-4"><strong>srcset</strong></td>
    <td>{{ srcSetAttr }}</td>
  </tr>
  <tr>
    <td class="pe-4"><strong>sizes</strong></td>
    <td>{{ sizesAttr }}</td>
  </tr>
</table>
