import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as contentblockActions from '../actions/sliders.action';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { SlidersService } from '../../services';

@Injectable()
export class SlidersEffects {
  constructor(private actions$: Actions, private service: SlidersService) {}

  LoadSliders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(contentblockActions.LoadSlider),
      mergeMap(({ id }) =>
        this.service.loadSlider(id).pipe(
          map(slider => contentblockActions.LoadSliderSuccess({ slider })),
          catchError(error => of(contentblockActions.LoadSliderFail({ error })))
        )
      )
    );
  });
}
