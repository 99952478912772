import * as fromContentSliders from './sliders.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'content-sliders';

export interface ContentSlidersState {
  sliders: fromContentSliders.SlidersState;
}

export const reducers: ActionReducerMap<ContentSlidersState> = {
  sliders: fromContentSliders.SlidersReducer,
};

export const getContentSlidersState = createFeatureSelector<ContentSlidersState>(featureKey);

export const metaReducers: MetaReducer<ContentSlidersState>[] = !environment.production ? [] : [];
