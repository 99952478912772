import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, of, tap } from 'rxjs';
import { LikesService } from '../../services';

import * as actions from '../actions';

@Injectable()
export class LikesEffects {
  constructor(private actions$: Actions, private service: LikesService, @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {}

  LikeContent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LikeContent),
      switchMap(({ id }) =>
        this.service.likeInitiative(id).pipe(
          map(content => actions.LikeContentSuccess({ content })),
          catchError(error => of(actions.LikeContentFail({ error })))
        )
      )
    );
  });

  LoadLikes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LoadLikes),
      switchMap(({ id }) =>
        this.service.loadLikes(id).pipe(
          map(content => actions.LoadLikesSuccess({ content })),
          catchError(error => of(actions.LoadLikesFail({ error })))
        )
      )
    );
  });

  InitLikes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.InitLikes),
      map(() => {
        let myLikes: number[] = [];

        if (isPlatformBrowser(this.platformId)) {
          const localStorageLikes = window.localStorage.getItem('likedContent');
          if (localStorageLikes) {
            myLikes = <number[]>JSON.parse(localStorageLikes);
          }
        }
        return actions.InitLikesSuccess({ likes: myLikes });
      })
    );
  });

  likeContentSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.LikeContentSuccess),
        tap(({ content }) => {
          let myLikes: number[] = [content.id];

          if (isPlatformBrowser(this.platformId)) {
            const localStorageLikes = window.localStorage.getItem('likedContent');
            if (localStorageLikes) {
              myLikes = [...(<number[]>JSON.parse(localStorageLikes)), content.id];
            }

            window.localStorage.setItem('likedContent', JSON.stringify(myLikes));
          }
        })
      );
    },
    { dispatch: false }
  );
}
