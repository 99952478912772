<app-auth-layout [title]="prefix + '-titel' | fromDictionary">
  <ng-container form>
    <app-alert
      *ngIf="result?.error"
      [status]="'danger'"
      [message]="result?.error ? result?.error : (prefix + 'formulier-error-tekst' | fromDictionary)"
      class="d-block mb-3"
    ></app-alert>

    <fstr-dynamic-form
      [appearance]="'fill'"
      *ngIf="!result?.succeeded"
      [config]="formFields"
      (formSubmit)="requestPasswordReset($event)"
      [showSubmit]="false"
      class="theme-light"
    >
      <div slot="form-actions">
        <button type="submit" class="button button--primary">
          {{ prefix + '-formulier-verzenden-knoptekst' | fromDictionary }}
        </button>
      </div>
    </fstr-dynamic-form>

    <app-alert
      *ngIf="result?.succeeded"
      [title]="prefix + '-formulier-succes-titel' | fromDictionary"
      [status]="'success'"
      [message]="prefix + '-formulier-succes-tekst' | fromDictionary"
      class="d-block mt-3"
    ></app-alert>
  </ng-container>
</app-auth-layout>
