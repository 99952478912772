<ng-container *ngIf="group.get(config.name) as field">
  <div
    class="custom-field custom-field--has-label type--wysiwy"
    [class.has--label]="!!config.label"
    [class.has--sub-label]="!!config.subLabel"
    [formGroup]="group"
    [ngClass]="{
      'custom-field--invalid': showError(field)
    }"
  >
    <label class="label">
      <span [innerHTML]="config.label"></span>
      <span *ngIf="isRequired(field)">*</span>
    </label>
    <div class="custom-field-subLabel" *ngIf="config.subLabel" [innerHTML]="config.subLabel"></div>

    <quill-editor
      *ngIf="config?.maxLength"
      #editor
      [modules]="modules"
      [placeholder]="config.placeholder || ''"
      [formControlName]="config.name"
      [minLength]="config['minLength'] || 0"
      [maxLength]="config['maxLength']"
      class="custom-field__field"
    ></quill-editor>

    <quill-editor
      *ngIf="!config?.maxLength"
      #editor
      [modules]="modules"
      [placeholder]="config.placeholder || ''"
      [formControlName]="config.name"
      [minLength]="config['minLength'] || 0"
      class="custom-field__field"
    ></quill-editor>

    <!-- TODO errors werkend laten maken -->
    <div class="custom-field__error-wrapper">
      <!--<mat-error *ngIf="showError(field)">
        {{ field | formError:config?.customValidationMessages }}
      </mat-error>-->
    </div>
  </div>
</ng-container>
