import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/likes.action';

export interface LikesState {
  likes: { [id: number]: number };
  loading: boolean;
  loaded: boolean;
  liked: number[];
  initialized: boolean;
  error?: any;
}

export const initialState: LikesState = {
  likes: {},
  loading: false,
  loaded: false,
  initialized: false,
  liked: [],
};

export const LikesReducer = createReducer(
  initialState,
  on(actions.LoadLikes, (state: LikesState, { id }) => {
    return {
      ...state,
      loaded: false,
      loading: true,
    };
  }),
  on(actions.LoadLikesSuccess, (state: LikesState, { content }) => {
    const likes = {
      ...state.likes,
      [content.id]: content.likes.likes,
    };

    return {
      ...state,
      likes,
      loaded: true,
      loading: false,
    };
  }),
  on(actions.LoadLikesFail, (state: LikesState, { error }) => {
    return {
      ...state,
      loaded: false,
      loading: false,
      error,
    };
  }),
  on(actions.LikeContent, (state: LikesState, { id }) => {
    return {
      ...state,
      loading: true,
      error: undefined,
    };
  }),
  on(actions.LikeContentSuccess, (state: LikesState, { content }) => {
    const likes = {
      ...state.likes,
      [content.id]: content.likes.likes,
    };
    return {
      ...state,
      likes,
      liked: [...state.liked, content.id],
      loaded: true,
      loading: false,
    };
  }),
  on(actions.LikeContentFail, (state: LikesState, { error }) => {
    return {
      ...state,
      loading: false,
      error,
    };
  }),
  on(actions.InitLikesSuccess, (state: LikesState, { likes }) => {
    return {
      ...state,
      liked: [...likes],
      initialized: true,
    };
  })
);

export const getLikesEntities = (state: LikesState) => state.likes;
export const getLikesLoaded = (state: LikesState) => state.loaded;
export const getLikesLoading = (state: LikesState) => state.loading;
export const getMyLikes = (state: LikesState) => state.liked;
export const getLikesError = (state: LikesState) => state.error;
export const getLikesInitialized = (state: LikesState) => state.initialized;
