<section class="content-slider" *ngIf="slider$ | async as slider">
  <h1 class="cdk-visually-hidden" *ngIf="!slider.title">{{ 'slider-screenreader-titel' | fromDictionary }}</h1>
  <a class="skiplink" id="start-slider" [routerLink]="[]" (click)="focusTo('#eind-slider')">Sla Slider over</a>

  <!--<section *ngIf="testSlider as slider">-->
  <h2 *ngIf="slider.title">{{ slider.title }}</h2>
  <p *ngIf="slider.intro">{{ slider.intro }}</p>

  <ng-container *ngIf="slider$ | async as slider">
    <div class="swiper-container">
      <swiper-container class="swiper" init="false" #swiper>
        <swiper-slide class="swiper-slide" *ngFor="let item of slider.slides; trackBy: trackByFn; let i = index">
          <!--<img [src]="item.imageUrl" />-->

          <fstr-image
            [objectFitMode]="true"
            [image]="{
              url: item.imageUrl,
              anchor: item.imageAnchor,
            }"
            [imageSizes]="[500, 900, 1200, 1600, 1900]"
            [width]="721"
            [height]="481"
            [mode]="'pad'"
          ></fstr-image>

          <div class="swiper-slide__caption" *ngIf="item.text">
            <p>{{ item.text }}</p>
          </div>
        </swiper-slide>
      </swiper-container>

      <div class="swiper-navigation">
        <button class="button button-icon button--light button--lg" (click)="slidePrev()" [disabled]="isBeginning">
          <app-icon anchor="icon-arrow-left"></app-icon>
          <span class="sr-only">Vorige</span>
        </button>
        <button class="button button-icon button--light button--lg" (click)="slideNext()" [disabled]="isEnd">
          <app-icon anchor="icon-arrow-right"></app-icon>
          <span class="sr-only">Volgende</span>
        </button>
      </div>
    </div>

    <swiper-container class="swiper-thumbs mt-2" init="true" #swiperThumb>
      <swiper-slide class="swiper-thumb-slide swiper-slide" *ngFor="let item of slider.slides; trackBy: trackByFn; let i = index">
        <button class="thumb-slide-button">
          <fstr-image
            [objectFitMode]="true"
            [image]="{
              url: item.imageUrl,
              anchor: item.imageAnchor,
            }"
            [imageSizes]="[300, 500, 900]"
            [width]="721"
            [height]="481"
            [mode]="'pad'"
          ></fstr-image>
        </button>
      </swiper-slide>
    </swiper-container>
  </ng-container>

  <a class="skiplink" id="eind-slider" [routerLink]="[]" (click)="focusTo('#start-slider')">Terug naar begin slider</a>
</section>
