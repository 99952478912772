import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as metaActions from '../actions/meta.action';

import { tap } from 'rxjs/operators';
import { Meta, Title, MetaDefinition } from '@angular/platform-browser';
import { StringUtility } from '@teamfoster/sdk/text-utility';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class MetaEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private doc: any
  ) {}

  setTitle$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(metaActions.SetTitle),
        tap(({ title }) => {
          this.title.setTitle(title);
        })
      );
    },
    { dispatch: false }
  );

  setCanonical$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(metaActions.SetCanonical),
        tap(({ url }) => {
          let link: HTMLLinkElement = this.doc.createElement('link');
          link.setAttribute('rel', 'canonical');
          this.doc.head.appendChild(link);
          link.setAttribute('href', url);
        })
      );
    },
    { dispatch: false }
  );

  setMeta$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(metaActions.SetTags),
        tap(({ properties }) => {
          const keys = Object.keys(properties);
          const data: any = keys.map(a => {
            const property = a;
            const content = properties[a];
            if (property === 'description') {
              return { name: property, content };
            }

            return { property, content };
          });
          this.meta.addTags(data);
        })
      );
    },
    { dispatch: false }
  );

  SetMetadata$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(metaActions.SetMetadata),
        tap(({ data }) => {
          this.title.setTitle(`${data.title} - Onze IJssel`);
          this.meta.addTags([
            { name: 'description', content: StringUtility.Truncate(StringUtility.StripHTML(data.description), 157, '...') },
            { property: 'og:image', content: data.imageUrl },
            { property: 'og:title', content: `${data.title} - Onze IJssel` },
            { property: 'og:description', content: StringUtility.Truncate(StringUtility.StripHTML(data.description), 157, '...') },
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'twitter:image', content: data.imageUrl },
            { name: 'twitter:title', content: `${data.title} - Onze IJssel` },
            { name: 'twitter:description', content: StringUtility.Truncate(StringUtility.StripHTML(data.description), 157, '...') },
          ]);
        })
      );
    },
    { dispatch: false }
  );
}
