<article *ngIf="contentCard$ | async as contentCard" class="container">
  <section class="d-flex row section-inset-y content-card">
    <figure class="col-md-4 content-card__figure col-12" *ngIf="contentCard.imageUrl">
      <fstr-image [image]="{ url: contentCard.imageUrl }"></fstr-image>
    </figure>

    <main class="bg-primary card col-12 content-card__main" *ngIf="contentCard.id" [class.col-md-8]="contentCard.imageUrl">
      <h2 class="text-light h3">{{ contentCard.title }}</h2>
      <div class="text-light" [innerHTML]="contentCard.text"></div>
      <footer *ngIf="contentCard.button" class="col-12 content-card__footer mt-4">
        <a class="button button--light" [target]="contentCard.newTab ? '_blank' : '_self'" [href]="contentCard.button?.url">
          {{ contentCard.button?.text }}
        </a>
      </footer>
    </main>
  </section>
</article>
