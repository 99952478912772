import { createReducer, on } from '@ngrx/store';
import { Slider } from '../../models';
import * as pageActions from '../actions/sliders.action';

export interface SlidersState {
  entities: { [id: number]: Slider };
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: SlidersState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const SlidersReducer = createReducer(
  initialState,
  on(pageActions.LoadSlider, (state: SlidersState, { id }) => {
    return {
      ...state,
      loaded: false,
      loading: true,
    };
  }),
  on(pageActions.LoadSliderSuccess, (state: SlidersState, { slider }) => {
    let entities = {
      ...state.entities,
    };
    if (slider) {
      entities[slider.id] = slider;
    }
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.LoadSliderFail, (state: SlidersState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getSlidersEntities = (state: SlidersState) => state.entities;
export const getSlidersLoading = (state: SlidersState) => state.loading;
export const getSlidersLoaded = (state: SlidersState) => state.loaded;
