import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginData, AuthData, RegisterData, UpdatePasswordData, Profile } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  signIn(data: LoginData) {
    return this.http.post<AuthData>(`${this.BASE_URL}api/auth/login`, data);
  }

  checkAuth() {
    return this.http.get<AuthData>(`${this.BASE_URL}api/auth`, { withCredentials: true });
  }

  SignOut() {
    return this.http.post<any>(`${this.BASE_URL}api/auth/logout`, null);
  }

  forgotPassword(email: string) {
    return this.http.post<any>(`${this.BASE_URL}api/auth/forgot-password?email=${email}`, null);
  }

  resetPassword(token: string, uid: string, passwords: any) {
    return this.http.post<any>(`${this.BASE_URL}api/auth/reset-password?uid=${uid}&token=${encodeURIComponent(token)}`, passwords);
  }

  register(regData: RegisterData) {
    return this.http.post<AuthData>(`${this.BASE_URL}api/auth/register`, regData);
  }

  confirmAccount(token: string, uid: string) {
    return this.http.post<any>(`${this.BASE_URL}api/auth/confirm-account?token=${token}&uid=${uid}`, null);
  }

  updateAccount(data: any) {
    const formData: FormData = new FormData();
    if (data.image instanceof Blob) {
      formData.append('image', data.image);
    } else {
      formData.append('image', <Blob>(null as unknown));
    }
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);

    data.categories.forEach((category: any, i: number) => {
      formData.append(`categories[${i}].id`, category.id.toString());
      formData.append(`categories[${i}].title`, category.title);
    });
    formData.append('id', data.id?.toString());
    return this.http.put<Profile>(`${this.BASE_URL}api/auth/me`, formData);
  }

  updatePassword(data: UpdatePasswordData) {
    return this.http.post<any>(`${this.BASE_URL}api/auth/update-password`, data);
  }

  updateAccountVisibility(privacyLevel: 0 | 1 | 2) {
    return this.http.put<Profile>(`${this.BASE_URL}api/auth/me/visibility`, privacyLevel);
  }
}
