import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Image } from '../../models';

@Component({
  selector: 'app-actions-card',
  templateUrl: './actions-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsCardComponent {
  @Input() image!: Image;
  @Input() altText!: string;
  @Input() title!: string;
  @Input() text!: string;
  @Input() link!: string;
}
