import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseContent } from 'src/app/content/models';
import { Event } from 'src/app/events/models';
import { Initiative } from 'src/app/initiatives/models';
import { LandingPage } from 'src/app/landing-pages/models';
import { Story } from 'src/app/stories/models';
import { SearchResult } from '../../models';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultsComponent implements OnChanges {
  @Input() results?: Record<string, SearchResult<BaseContent | LandingPage>[]>;
  @Input() loading: boolean = false;
  @Input() searchQuery?: string;

  Initiative!: Initiative;
  Event!: Event;
  Story!: Story;
  LandingPage!: LandingPage;

  maxResultsPerRow = 3;
  stepSizeLoadMore = 9;

  visibleResultCount: { [key: string]: number } = {};

  preferredOrder = ['Activiteit', 'Verhaal', 'Initiatief', 'Pagina'];

  byPrefferedOrder = (
    a: KeyValue<string, SearchResult<BaseContent | LandingPage>[]>,
    b: KeyValue<string, SearchResult<BaseContent | LandingPage>[]>
  ): number => {
    console.log(a.key);
    return this.preferredOrder.indexOf(a.key) - this.preferredOrder.indexOf(b.key);
  };

  get hasResults() {
    return this.results ? Object.keys(this.results).length : 0;
  }

  get resultCount() {
    return this.hasResults
      ? Object.keys(this.results!)
          .map(key => this.results![key].length)
          .reduce((a, b) => a + b, 0)
      : 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['results']) {
      this.initVisibleResultCount();
    }
  }

  initVisibleResultCount() {
    if (!this.hasResults) {
      this.visibleResultCount = {};
    }

    // Set count for every row to initial state of maxResultsPerRow
    this.visibleResultCount = Object.keys(this.results!).reduce((acc, key) => ({ ...acc, [key]: this.maxResultsPerRow }), {});
  }

  showMore(key: string) {
    if (!key || !this.visibleResultCount[key]) {
      return;
    }

    this.visibleResultCount[key] += this.stepSizeLoadMore;
  }
}
