import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgxGlideComponent } from 'ngx-glide';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderComponent implements OnInit {
  @Input() config = {};
  @Input() total = 0;
  @Input() cursorArrow = true;

  default = {
    gap: 24,
    showArrows: false,
    showBullets: true,
    startAt: 0,
    listenToEvents: true,
    type: 'carousel',
    bound: false,
    rewind: false,
    perView: 1,
    breakpoints: {
      600: {
        perView: 1,
      },
    },
  };

  settings = this.default;
  cursor = {
    x: 0,
    y: 0,
    visible: false,
    direction: 'left',
    scrolling: false,
  };

  @ViewChild(NgxGlideComponent, { static: false }) ngxGlide!: NgxGlideComponent;

  constructor(private cd: ChangeDetectorRef) {}

  get activePage() {
    if (!this.ngxGlide) {
      return;
    }

    return this.ngxGlide.getIndex();
  }

  get showControls() {
    if (!this.ngxGlide) {
      return false;
    }

    return this.checkGlideSlides();
  }

  checkGlideSlides() {
    // check perview with breakpoints
    const perView = this.ngxGlide['glide']['_o']['perView'] ? this.ngxGlide['glide']['_o']['perView'] : 0;

    if (this.total > perView) {
      this.ngxGlide.enable();
      return true;
    }

    this.ngxGlide.disable();
    return false;
  }

  ngOnInit() {
    this.settings = { ...this.default, ...this.config };
  }

  moveSlider() {
    if (this.cursor.direction === 'left') {
      this.ngxGlide.go('<');
    } else {
      this.ngxGlide.go('>');
    }
  }

  nextSlide() {
    this.ngxGlide.go('>');
  }

  prevSlide() {
    this.ngxGlide.go('<');
  }

  updateCursor(e: any) {
    if (!this.cursorArrow) {
      this.cursor.visible = false;
      return;
    }

    const target = e.target;

    if (target.tagName === 'A') {
      this.cursor.visible = false;
    } else {
      this.cursor.visible = true;
    }

    if (this.cursor.visible && this.total > 1) {
      const halfWindow = window.innerWidth / 2;
      this.cursor.x = e.x;
      this.cursor.y = e.y;
      if (e.x > halfWindow) {
        this.cursor.direction = 'right';
      } else {
        this.cursor.direction = 'left';
      }
    }
  }

  focusTo(element: string) {
    const el = document.querySelector(element);
    if (el && (el as HTMLElement)) {
      (el as HTMLElement).focus();
    }
  }

  // disable slider
}
