<section class="slider" [class.set--cursor-arrow]="cursorArrow" [class.set--slider]="total > settings.perView">
  <h1 class="cdk-visually-hidden">{{ 'slider-screenreader-titel' | fromDictionary }}</h1>
  <a class="skiplink" id="start-slider" *ngIf="total > settings.perView" [routerLink]="[]" (click)="focusTo('#eind-slider')">
    Sla Slider over
  </a>

  <div
    class="slider__wrapper"
    [class.set--start-position]="!activePage"
    [class.set--inactive]="total <= settings.perView"
    (mouseenter)="cursor.visible = true"
    (mouseleave)="cursor.visible = false"
    (mousemove)="updateCursor($event)"
    (click)="total > 1 && cursor.visible ? moveSlider() : ''"
  >
    <ngx-glide
      #NgxGlideComponent
      [breakpoints]="settings.breakpoints"
      [gap]="settings.gap"
      [bound]="settings.bound"
      [type]="settings.type"
      [rewind]="settings.rewind"
      [listenToEvents]="settings.listenToEvents"
      [showArrows]="settings.showArrows"
      [showBullets]="settings.showBullets"
      [perView]="settings.perView"
      [startAt]="settings.startAt"
      (mountedAfter)="checkGlideSlides()"
      (resized)="checkGlideSlides()"
    >
      <ng-content></ng-content>
    </ngx-glide>

    <ng-container *ngIf="showControls">
      <button aria-label="vorige slide" class="slider__nav__button slider__nav__button--prev" (click)="prevSlide()">
        <span class="button button-icon button--light button--lg">
          <app-icon anchor="icon-arrow-left"></app-icon>
          <span class="sr-only">Vorige</span>
        </span>
      </button>
      <button aria-label="volgende slide" class="slider__nav__button slider__nav__button--next" (click)="nextSlide()">
        <span class="button button-icon button--light button--lg">
          <app-icon anchor="icon-arrow-right"></app-icon>
          <span class="sr-only">Volgende</span>
        </span>
      </button>
    </ng-container>
  </div>
  <div
    class="slider__cursor"
    (click)="moveSlider()"
    *ngIf="cursor.visible && total > 1 && cursorArrow"
    [style.left.px]="cursor.x"
    [style.top.px]="cursor.y"
  >
    <div class="slider__cursor__pointer type--{{ cursor.direction }}"></div>
  </div>
  <a class="skiplink" id="eind-slider" [routerLink]="[]" (click)="focusTo('#start-slider')" *ngIf="showControls">Terug naar begin slider</a>
</section>
