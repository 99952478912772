import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { debounceTime, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBarComponent implements OnInit, OnDestroy, OnChanges {
  @Output() valueChanges = new EventEmitter<string>();
  @Input() patchValue?: string | null | undefined;

  seachForm: FormGroup = this.fb.group({
    searchControl: [''],
  });

  unsubscribe$ = new Subject<void>();

  constructor(private fb: FormBuilder, private lang: LanguageService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['patchValue'] && this.patchValue) {
      this.seachForm.get('searchControl')?.patchValue(this.patchValue);
    }
  }

  ngOnInit(): void {
    this.seachForm.valueChanges.pipe(debounceTime(500), takeUntil(this.unsubscribe$)).subscribe(a => {
      const val = a['searchControl'];
      if (val) {
        this.valueChanges.emit(val);
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
