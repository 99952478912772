<section class="section-inset-y auth-layout">
  <div class="container container-size-xxs auth-layout__main">
    <header class="text-center mb-4">
      <span class="fw-bold font-secondary type-body-md text-secondary">{{ 'account-authenticatie-subtitel' | fromDictionary }}</span>
      <h2 class="text-primary">
        {{ title }}
        <app-icon anchor="icon-squiggle" class="heading-squiggle"></app-icon>
      </h2>
    </header>
    <article class="card">
      <main>
        <ng-content select="[form]"></ng-content>
      </main>

      <footer #footer [class.mt-3]="footer?.children?.length">
        <ng-content select="[footer]"></ng-content>
      </footer>
    </article>
  </div>

  <div class="auth-layout__background">
    <!-- Original source/tutorial: https://dev.to/theliquidcharcoal/pure-html-animation-animate-svg-with-animate-2a5m -->
    <svg aria-hidden="true" width="100%" height="200px" fill="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="var(--bs-secondary)"
        d="
          M0 67
          C 273,183
            822,-40
            1920.00,106

          V 359
          H 0
          V 67
          Z"
      >
        <animate
          repeatCount="indefinite"
          fill="url(#grad1)"
          attributeName="d"
          dur="15s"
          attributeType="XML"
          values="
            M0 77
            C 473,283
              822,-40
              1920,116

            V 359
            H 0
            V 67
            Z;

            M0 77
            C 473,-40
              1222,283
              1920,136

            V 359
            H 0
            V 67
            Z;

            M0 77
            C 973,260
              1722,-53
              1920,120

            V 359
            H 0
            V 67
            Z;

            M0 77
            C 473,283
              822,-40
              1920,116

            V 359
            H 0
            V 67
            Z
            "
        ></animate>
      </path>
      <path
        stroke="var(--bs-primary)"
        strokeWidth="4"
        fill="none"
        d="
          M0 67
          C 273,183
            822,-40
            1920.00,106

          V 359
          H 0
          V 67
          Z"
      >
        <animate
          repeatCount="indefinite"
          fill="url(#grad1)"
          attributeName="d"
          dur="15s"
          begin="-.75s"
          attributeType="XML"
          values="
            M0 77
            C 473,283
              822,-40
              1920,116

            V 359
            H 0
            V 67
            Z;

            M0 77
            C 473,-40
              1222,283
              1920,136

            V 359
            H 0
            V 67
            Z;

            M0 77
            C 973,260
              1722,-53
              1920,120

            V 359
            H 0
            V 67
            Z;

            M0 77
            C 473,283
              822,-40
              1920,116

            V 359
            H 0
            V 67
            Z
            "
        ></animate>
      </path>
    </svg>
  </div>
</section>
