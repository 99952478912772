import { createAction, props } from '@ngrx/store';
import { BaseContent } from 'src/app/content/models';

export const LikeContent = createAction('[Likes] Like Content', props<{ id: number }>());
export const LikeContentSuccess = createAction('[Likes] Like Content Success', props<{ content: BaseContent }>());
export const LikeContentFail = createAction('[Likes] Like Content Fail', props<{ error: any }>());

export const LoadLikes = createAction('[Likes] Load Likes', props<{ id: number }>());
export const LoadLikesSuccess = createAction('[Likes] Load Likes Success', props<{ content: BaseContent }>());
export const LoadLikesFail = createAction('[Likes] Load Likes Fail', props<{ error: any }>());

export const InitLikes = createAction('[Likes] Init Likes');
export const InitLikesSuccess = createAction('[Likes] Init Likes Success', props<{ likes: number[] }>());
export const InitLikesFail = createAction('[Likes] Init Likes Fail', props<{ error: any }>());
