<app-auth-layout [title]="prefix + '-titel' | fromDictionary" icon="inloggen">
  <ng-container form>
    <div class="form">
      <app-alert
        *ngIf="activated$ | async"
        class="d-block mb-3"
        [message]="prefix + '-formulier-succes-tekst' | fromDictionary"
        [status]="'success'"
        [title]="prefix + '-formulier-succes-titel' | fromDictionary"
      ></app-alert>

      <a class="button button--primary" [routerLink]="['/', 'account', 'inloggen']">
        {{ prefix + '-formulier-succes-knoptekst' | fromDictionary }}
      </a>

      <app-alert
        *ngIf="error$ | async as errors"
        class="d-block mt-4"
        [message]="prefix + '-formulier-error-tekst' | fromDictionary"
        [status]="'danger'"
        [title]="prefix + '-formulier-error-titel' | fromDictionary"
        class="d-block mt-3"
      ></app-alert>
    </div>
  </ng-container>
</app-auth-layout>
