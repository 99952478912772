import * as fromResults from './search-results.reducer';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

export const featureKey = 'search';

export interface SearchState {
  results: fromResults.SearchResultsState;
}

export const reducers: ActionReducerMap<SearchState> = {
  results: fromResults.searchResultsReducer,
};

export const getSearchState = createFeatureSelector<SearchState>(featureKey);
