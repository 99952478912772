import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActivateAccountComponent } from './containers/activate-account/activate-account.component';
import { ForgotPasswordComponent } from './containers/forgot-password/forgot-password.component';
import { RegisterComponent } from './containers/register/register.component';
import { ResetPasswordComponent } from './containers/reset-password/reset-password.component';
import { SignInComponent } from './containers/sign-in/sign-in.component';

const routes: Routes = [
  {
    path: 'inloggen',
    component: SignInComponent,
  },
  {
    path: 'registreren',
    component: RegisterComponent,
  },
  {
    path: 'wachtwoord-vergeten',
    component: ForgotPasswordComponent,
  },
  {
    path: 'bevestig-account',
    component: ActivateAccountComponent,
  },
  {
    path: 'wachtwoord-resetten',
    component: ResetPasswordComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AtuhRoutingModule {}
