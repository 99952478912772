import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Slider } from '../models';

@Injectable({
  providedIn: 'root',
})
export class SlidersService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}
  loadSlider(id: number) {
    return this.http.get<Slider>(`${this.BASE_URL}api/slider/${id}`);
  }
}
