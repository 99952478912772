<article class="initiative-card" [class.set--inverted]="inverted">
  <a [routerLink]="initiative.url" class="block-link" [attr.aria-label]="'meer over ' + initiative.title">
    <figure class="initiative-card__figure mb-sm-4 col-12 col-sm-12">
      <div class="initiative-card__figure__overlay p-3 p-md-4">
        <span *ngIf="label" class="chip chip--primary chip--label d-none d-sm-inline-flex">{{ label }}</span>
      </div>

      <app-image
        [alt]="initiative.image.caption || initiative.title"
        [image]="initiative.image"
        [sizes]="{ md: 4, xl: 3 }"
        [imageSizes]="[200, 400, 800]"
        [width]="700"
        [height]="500"
      ></app-image>
    </figure>
    <main class="col-12 col-sm-12 initiative-card__main">
      <div class="px-sm-4 flex-1">
        <strong class="initiative-card__subtitle text-secondary mb-1 lh-base" *ngIf="initiative.subtitle">
          {{ initiative.subtitle }}
        </strong>

        <h2 class="fw-bold font-secondary initiative-card__title h6 mt-2 lh-sm" [ngClass]="inverted ? 'text-light' : 'text-primary'">
          {{ initiative.title }}
        </h2>

        <span class="date mb-2">{{ initiative.createdOn | date : 'd MMM y' }}</span>

        <p
          class="initiative-card__text mb-0 d-none d-md-block"
          [ngClass]="inverted ? 'text-light' : 'text-primary'"
          *ngIf="initiative.intro && showIntro"
        >
          {{ initiative.intro | truncate : 80 }}
        </p>
      </div>
      <footer class="mt-auto mb-0 initiative-card__footer py-4">
        <ul class="theme-list d-flex">
          <li class="theme-list__item" *ngFor="let item of initiative.themes">
            <button
              tabindex="-1"
              [attr.aria-label]="'categorie ' + item.title"
              class="chip chip-icon chip--sm"
              [ngClass]="inverted ? 'chip--light' : 'chip--primary'"
              [title]="item.title"
            >
              <app-icon [anchor]="item.icon"></app-icon>
            </button>
          </li>
        </ul>

        <span class="chip chip--label chip--tertiary chip--sm mt-2" *ngIf="getDistance() as metres">
          {{ metres / 1000 | number : '1.0-1' }}km
        </span>

        <a
          [routerLink]="initiative.url"
          tabindex="-1"
          [attr.aria-label]="initiative.title"
          class="button button-link ms-auto me-0"
          [ngClass]="inverted ? 'button--light' : 'button--primary'"
        >
          <span class="d-none type-body-xs">Lees meer</span>
          <app-icon class="m-0" anchor="icon-arrow-right"></app-icon>
        </a>
      </footer>
    </main>
  </a>
</article>
