<nav *ngIf="breadcrumbs?.length">
  <ul class="breadcrumb-list type-body-xs" [attr.style]="'--breadcrumb-count:' + breadcrumbs.length" *ngIf="breadcrumbs.length">
    <li class="breadcrumb-list__item" *ngFor="let crumb of breadcrumbs; let i = index">
      <a *ngIf="crumb?.routerLink" class="breadcrumb" [routerLink]="crumb?.routerLink">
        <span class="breadcrumb__label">{{ crumb?.title }}</span>
      </a>
      <span *ngIf="!crumb?.routerLink" class="breadcrumb__label">{{ crumb?.title }}</span>
    </li>
  </ul>
</nav>
