import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as contentblockActions from '../actions/content-card.action';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { ContentCardService } from '../../services';

@Injectable()
export class ContentCardEffects {
  constructor(private actions$: Actions, private service: ContentCardService) {}

  LoadBugs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(contentblockActions.LoadContentCard),
      mergeMap(({ id }) =>
        this.service.loadContentCard(id).pipe(
          map(slider => contentblockActions.LoadContentCardSuccess({ slider })),
          catchError(error => of(contentblockActions.LoadContentCardFail({ error })))
        )
      )
    );
  });
}
