import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicFormField } from '@teamfoster/sdk/legacy-dynamic-form';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-form-date',
  templateUrl: './form-date.component.html',
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDateComponent implements OnInit, OnDestroy {
  config!: DynamicFormField;
  group!: UntypedFormGroup;

  range?: FormGroup;

  private _unsubscribe = new Subject<void>();

  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    const field = this.group.get(this.config.name)!;
    const val = this.config.value;

    this.range = this.fb.group({
      start: ['', this.config.validators],
      end: [''],
    });

    if (val) {
      this.range.patchValue(val);
    }

    this.range.valueChanges.pipe(takeUntil(this._unsubscribe)).subscribe(a => {
      this.updateFormValue();
    });

    // trigger error message..
    field.statusChanges.pipe(takeUntil(this._unsubscribe)).subscribe(a => {
      this.range?.markAllAsTouched();
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  get showError() {
    const field = this.group.get(this.config.name);

    if ((this.range?.dirty || this.range?.touched) && this.range?.invalid) {
      return true;
    }

    if (!field) {
      return;
    }
    return field.invalid && (field.touched || field.dirty);
  }

  isRequired(field: AbstractControl) {
    if (!field.validator) {
      return false;
    }
    const validator = field?.validator({} as AbstractControl);
    return validator && validator['required'];
  }

  updateFormValue() {
    const field = this.group.get(this.config.name);

    field?.setValue(this.range?.value);
  }
}
