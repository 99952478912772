import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Story } from 'src/app/stories/models';
import { getDistance } from 'geolib';

@Component({
  selector: 'app-story-card',
  templateUrl: './story-card.component.html',

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoryCardComponent {
  @Input() story!: Story;
  @Input() location!: GeolocationPosition | undefined | null;
  @Input() label?: string;

  getDistance() {
    if (!this.location || !this.story.location?.lat || !this.story.location?.lng) {
      return undefined;
    }
    return getDistance([this.story.location.lat, this.story.location.lng], [this.location.coords.latitude, this.location.coords.longitude]);
  }
}
