<div class="confirm" [ngClass]="cssClass">
  <div class="confirm__inner shadow--right">
    <mat-dialog-content class="mb-2 pb-0" [class.pt-3]="!title">
      <header class="mb-3 text-primary mat-dialog__header font-secondary" *ngIf="title">
        <h1 class="type-body-md mb-0 mat-dialog__header__title">{{ title }}</h1>
      </header>
      <div [innerHTML]="message" class="confirm__message text-center type-body-xs"></div>
    </mat-dialog-content>

    <mat-dialog-actions class="mt-3 mb-2 confirm__footer">
      <button class="button button--primary ms-auto me-2" (click)="confirmHandler(true)">Ja</button>
      <button class="button button--primary me-auto" (click)="confirmHandler(false)">Nee</button>
    </mat-dialog-actions>
  </div>
</div>
