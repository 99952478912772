<article class="card bg-light text-primary card--actions w-100">
  <figure class="avatar d-none d-md-block" *ngIf="image">
    <!-- <img [src]="image" [alt]="altText ?? title" /> -->
    <app-image [alt]="altText || title" [image]="image" [imageSizes]="[100, 200, 400]" [width]="100" [height]="100"></app-image>
  </figure>
  <main class="card__main">
    <h2 class="text-primary h5">{{ title }}</h2>
    <span class="text-primary" *ngIf="text">{{ text }}</span>
  </main>
  <a [routerLink]="link" [attr.aria-label]="title" class="button button--lg button-icon button--tertiary">
    <app-icon anchor="icon-arrow-right" [title]="title"></app-icon>
  </a>
</article>
