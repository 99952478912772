import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SearchResult } from '../models';
import { BaseContent } from 'src/app/content/models';
import { LandingPage } from 'src/app/landing-pages/models';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  Search(q: string) {
    return this.http.get<SearchResult<BaseContent | LandingPage>[]>(`${this.BASE_URL}api/search?q=${q}`);
  }
}
