import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { BaseContent } from 'src/app/content/models';

@Injectable({
  providedIn: 'root',
})
export class LikesService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  likeInitiative(id: number) {
    return this.http.post<BaseContent>(`${this.BASE_URL}api/content/${id}/like`, null);
  }

  loadLikes(id: number) {
    return this.http.get<BaseContent>(`${this.BASE_URL}api/content/${id}/likes`);
  }
}
