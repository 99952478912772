import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { LoginData } from '../../models';
import { getAuthError, getAuthSignedIn, getAuthSigningIn, RemoveErrors, SignIn } from '../../store';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { SetTitle } from '../../../store';
import { DynamicFormField } from '@teamfoster/sdk/legacy-dynamic-form';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
})
export class SignInComponent implements OnInit {
  signingIn$: Observable<boolean> = of(false);
  signedIn$: Observable<boolean> = of(false);
  error$?: Observable<any>;
  prefix = 'inloggen';

  signInForm = this.fb.group({});
  formFields: DynamicFormField[] = [];

  private dict = new FromDictionaryPipe(this.lang);

  constructor(private fb: FormBuilder, private store: Store, private lang: LanguageService) {}

  ngOnInit(): void {
    this.store.dispatch(RemoveErrors());
    this.formFields = [
      {
        inputType: 'email',
        label: this.dict.transform(`${this.prefix}-formulier-veld-label-email`),
        name: 'email',
        validators: [Validators.email, Validators.required],
        autocomplete: 'email',
        placeholder: this.dict.transform(`${this.prefix}-formulier-veld-placeholder-email`),
        fieldType: 'input',
        order: 0,
      },
      {
        inputType: 'password',
        label: this.dict.transform(`${this.prefix}-formulier-veld-label-wachtwoord`),
        name: 'password',
        validators: [Validators.required],
        autocomplete: 'password',
        placeholder: this.dict.transform(`${this.prefix}-formulier-veld-placeholder-wachtwoord`),
        fieldType: 'input',
        order: 1,
      },
    ];

    this.signingIn$ = this.store.select(getAuthSigningIn);
    this.signedIn$ = this.store.select(getAuthSignedIn);
    this.error$ = this.store.select(getAuthError);
    this.store.dispatch(
      SetTitle({ title: `${this.dict.transform(`${this.prefix}-titel`)} - ${this.dict.transform('meta-titel-suffix')}` })
    );
  }

  handleSubmit(e: any) {
    const loginData: LoginData = { email: e.email, password: e.password, isPersistent: true };
    this.store.dispatch(SignIn({ loginData }));
  }

  getErrorMessage(error: { succeeded: boolean; isLockedOut: boolean; isNotAllowed: boolean; requiresTwoFactor: boolean }) {
    if (error?.isNotAllowed) {
      return `${this.prefix}-formulier-error-tekst-activatie`;
    }

    if (error?.isLockedOut) {
      return `${this.prefix}-formulier-error-tekst-disabled`;
    }

    return `${this.prefix}-formulier-error-default`;
  }
}
