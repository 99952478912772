<div class="search">
  <!-- Nu nog hardcoded -->
  <h5 class="text-primary font-secondary fw-bold my-5" *ngIf="searchQuery">
    {{ resultCount }} resultaten voor
    <span class="text-secondary">"{{ searchQuery }}"</span>
  </h5>

  <!-- TODO: loader stijlen-->
  <!-- Dit divje wordt alleen getoond als het geladen word,
    je kan dit gewoon verplaatsen naar waar je het wilt hebben staan -->
  <div *ngIf="loading">laden...</div>

  <ul class="container p-0" *ngIf="hasResults">
    <ng-container *ngFor="let group of results | keyvalue : byPrefferedOrder">
      <li *ngIf="group.value.length">
        <h4 class="text-primary mt-5 mb-4 font-secondary fw-bold fs-6">{{ group.key }}</h4>
        <ul class="row">
          <li *ngFor="let result of group.value | slice : 0 : visibleResultCount[group.key]" class="col-xsm-6 col-xl-3 mb-3 mb-lg-5">
            <ng-container [ngSwitch]="result.type">
              <app-initiative-card
                *ngSwitchCase="'Initiatief'"
                [inverted]="true"
                [showIntro]="false"
                [initiative]="result.reference | cast : Initiative"
              ></app-initiative-card>
              <app-event-card *ngSwitchCase="'Activiteit'" [event]="result.reference | cast : Event"></app-event-card>
              <app-story-card *ngSwitchCase="'Verhaal'" [story]="result.reference | cast : Story"></app-story-card>
              <app-page-card *ngSwitchCase="'Pagina'" [page]="result.reference | cast : LandingPage"></app-page-card>
            </ng-container>
          </li>
          <li
            class="col-xsm-6 col-xl-3 mb-3 mb-lg-5 d-flex justify-content-center align-items-center"
            *ngIf="group.value.length - visibleResultCount[group.key] > 0"
          >
            <button class="button button--primary search__button" (click)="showMore(group.key)">
              <div class="font-secondary fw-bold h2 mb-0 search__button-txt">
                + {{ group.value.length - visibleResultCount[group.key] }}
              </div>
            </button>
          </li>
        </ul>
      </li>
    </ng-container>
  </ul>
</div>
