<a [routerLink]="['/', page.slug]" class="page-card block-link" [attr.aria-label]="'meer over ' + page.title">
  <article class="page-card__inner">
    <div class="page-card__title" [class.has-no-img]="!page.header.image.url">
      <h3 class="page-card__title-h1 font-primary text-light px-4 py-3">{{ page.title }}</h3>
    </div>

    <app-image
      *ngIf="page.header.image.url"
      class="event-card__img"
      [alt]="page.header.image.caption || page.title"
      [image]="page.header.image"
      [sizes]="{ md: 4, xl: 3 }"
      [imageSizes]="[300, 500, 800]"
      [width]="700"
      [height]="900"
    ></app-image>
  </article>
</a>
