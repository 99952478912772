<article class="p-5 bg-gray-100 mx-auto text-max-width-md d-md-flex contact-card">
  <div class="col-12 col-md-6">
    <span *ngIf="subtitle" class="type-body-sm text-secondary fw-bold">{{ subtitle }}</span>
    <h2 class="text-primary" *ngIf="title">{{ title }}</h2>
  </div>
  <main class="col-12 col-md-6">
    <div class="contact-card__info">
      <figure *ngIf="imageUrl" class="contact-card__figure">
        <app-image
          [alt]="name ? 'afbeelding van ' + name : 'afbeelding over ' + title"
          [image]="{ url: imageUrl }"
          [imageSizes]="[180, 360, 400]"
          [width]="165"
          [height]="165"
        ></app-image>
      </figure>

      <div class="contact-card__info__content flex-column text-break font-secondary text-primary">
        <h3 class="type-body-sm fw-bold" *ngIf="name">{{ name }}</h3>

        <span *ngIf="label" class="d-inline-block mb-3">{{ label }}</span>
        <a *ngIf="email" [href]="'mailto:' + email">{{ email }}</a>
      </div>
    </div>
  </main>
</article>
