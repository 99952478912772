<section class="related-events">
  <header class="related-events__header mb-0 mb-md-4" *ngIf="!compact">
    <span *ngIf="subtitle?.length" class="type-body-md text-secondary fw-bold w-100">
      {{ subtitle ?? ('gerelateerde-evenementen-subtitel' | fromDictionary) }}
    </span>
    <h1 *ngIf="title?.length" class="related-events__title h2 text-primary">
      {{ title ?? ('gerelateerde-evenementen-titel' | fromDictionary) }}
    </h1>
  </header>

  <main class="related-events__main py-2 py-md-4" *ngIf="events">
    <app-slider [cursorArrow]="false" [total]="events.length" [config]="sliderConfig">
      <div class="col-12 col-xsm-6 col-sm-6 col-md-6 col-lg-4" *ngFor="let event of events">
        <app-event-card [inverted]="true" [event]="event"></app-event-card>
      </div>
    </app-slider>
  </main>
</section>
