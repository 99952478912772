import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '../environments/environment';
import { DictionaryNgrxModule, DictionaryGuard, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { IconModule } from '@teamfoster/sdk/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { reducers, CustomSerializer, appEffects, metaReducers } from './store';
import { DynamicFormConfig, LegacyDynamicFormModule } from '@teamfoster/sdk/legacy-dynamic-form';
import { MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/legacy-snack-bar';

import localeNl from './nl';
import { registerLocaleData } from '@angular/common';

// not used in production
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NotFoundComponent } from './404/404.component';
import { CookieConfig, CookieNgrxModule } from '@teamfoster/sdk/cookie-ngrx';
import { MenuGuard, MenuNgrxModule } from '@teamfoster/sdk/menu-ngrx';
import { formControls } from './form/form.module';
import { AuthModule } from './auth/auth.module';
import { SocialMediaModule } from './social-media/social-media.module';
import { SearchModule } from './search/search.module';

import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { LikesModule } from './likes/likes.module';
import { DynamicHooksModule, HookParserEntry } from 'ngx-dynamic-hooks';
import { ContentSliderComponent } from './content-slider/components/content-slider/content-slider.component';

// Swiper
import { register } from 'swiper/element/bundle';
import { ContentCardComponent } from './content-story/components/content-card/content-card.component';
register();

const dynamicFormConfig: DynamicFormConfig = {
  genericInputErrorMessage: 'Het formulier is niet correct ingevuld, controleer de velden en probeer nogmaals.',
  genericServerErrorMessage:
    'Er is iets fout gegaan bij het versturen van het formulier, probeer het later nogmaals. Blijft het probleem voordoen neem dan contact met ons op.',
  formControls,
};

const cookieConfig: CookieConfig = {
  analyticsCode: 'UA-0000000-0',
  strippedContentPlaceholder: 'Deze inhoud wordt niet getoond omdat je geen marketing cookies hebt geaccepteerd',
  buttonSettings: 'Instellingen',
  buttonAcceptAll: 'Alles accepteren',
  buttonClose: 'Sluiten',
  strippedContentButton: 'Cookie-instellingen wijzigen',
  types: {
    analytical: true,
    functional: true,
    marketing: false,
  },
  showSettings: true,
};

// This automatically creates SelectorHookParsers for each listed component:
const componentParsers: Array<HookParserEntry> = [
  { component: ContentSliderComponent },
  { component: ContentCardComponent },
  // ...
];

registerLocaleData(localeNl, 'nl');
@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    CoreModule,
    SearchModule,
    MatInputModule,
    MatFormFieldModule,
    IconModule.forRoot({ spritesheetUrl: 'assets/gfx/svg-sprite.svg' }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot(appEffects),
    StoreRouterConnectingModule.forRoot({ serializer: CustomSerializer }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    DictionaryNgrxModule.forRoot({}),
    CookieNgrxModule.forRoot(cookieConfig),
    LegacyDynamicFormModule.forRoot(dynamicFormConfig),
    MenuNgrxModule.forRoot({}),
    BrowserAnimationsModule,
    AuthModule,
    SocialMediaModule,
    LikesModule,
    DynamicHooksModule.forRoot({ globalParsers: componentParsers }),
  ],
  providers: [
    LanguageService,
    { provide: LOCALE_ID, useValue: 'nl' },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 1500, panelClass: ['fw-bold'], hasAction: false } },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
