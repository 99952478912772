import { Component, Input, OnInit, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import { ShowCookieSettings } from '@teamfoster/sdk/cookie-ngrx';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { SocialChannel } from 'src/app/social-media/models';
import * as fromAuth from '../../../auth/store';
import * as fromMenu from '@teamfoster/sdk/menu-ngrx';
import { MenuItem } from '@teamfoster/sdk/menu-ngrx';
import { HttpStatusCodeService } from '../../http-status-code.service';
import { BehaviorSubject, filter } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  @Input() schema: any;
  @Input() socialChannels: SocialChannel[] | null = [];
  @Input() footerNav: fromMenu.MenuItem[] = [];
  @Input() isSignedIn?: boolean;
  @Input() showStatusCode: boolean = false;
  @Input() socials: [] = [];
  @Input() domain: string = '';
  year = new Date().getFullYear();

  private dict = new FromDictionaryPipe(this.lang);

  // showStatusCode: boolean = false;

  constructor(private store: Store, private lang: LanguageService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.schema = {
      '@context': 'http://schema.org',
      '@type': this.dict.transform('json-ld-meta-type'),
      'name': this.dict.transform('meta-titel'),
      'url': this.domain,
      'logo': `${this.domain}/assets/gfx/logo-onze-ijssel.svg`,
      'contactPoint': {
        '@type': 'ContactPoint',
        'email': this.dict.transform('contact-email'),
        'contactType': 'customer service',
      },
      'sameAs': [],
    };

    this.socials.forEach(s => {
      const socialLink = s; // to do config with social buttons
      this.schema.sameAs.push(socialLink);
    });

    // this.showStatusCode = this.router.url === '/404';

    // this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(a => {
    //   this.showStatusCode = (<NavigationEnd>a).url === '/404';
    // });
  }

  openCookie() {
    this.store.dispatch(ShowCookieSettings());
  }
  signOut() {
    this.store.dispatch(fromAuth.SignOut());
  }
  trackByFn(index: number, item: MenuItem) {
    return item.guid;
  }
}
