import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, AbstractControl } from '@angular/forms';
import { DynamicFormField, SelectOption } from '@teamfoster/sdk/dynamic-form';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-form-themes-chooser',
  templateUrl: './form-themes-chooser.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormThemesChooserComponent implements OnInit, OnDestroy {
  config!: DynamicFormField;
  group!: UntypedFormGroup;

  checkBoxes = this.fb.group({});

  private _unsubscribe = new Subject<void>();

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.config['selectOptions']?.forEach((option: SelectOption) => {
      const initialValue = this.config.value && this.config.value.some((b: any) => b.id === option.id);
      this.checkBoxes.addControl(`${this.config.name}_option_${option.id}`, this.fb.control(initialValue));
    });

    // Update checkboxgroup value to array of selected checkboxes
    this.checkBoxes.valueChanges.pipe(takeUntil(this._unsubscribe)).subscribe(val => {
      let selectedAsArray: string[] | null = Object.keys(val).filter(k => val[k]) || [];
      selectedAsArray = selectedAsArray.length > 0 ? selectedAsArray : null;

      const objectArray =
        this.config.selectOptions?.filter(a => {
          const i = (selectedAsArray || []).indexOf(`${this.config.name}_option_${a.id}`);
          return i >= 0;
        }) || [];

      this.group.get(this.config.name)?.patchValue(objectArray);
    });

    // If value changes outside component (patchvalue) set checkboxvalues
    this.group.get(this.config.name)?.valueChanges.subscribe(values => {
      if (!values?.length) {
        return;
      }
      values.forEach((name: string) => {
        const ctrl = this.checkBoxes.controls[`${this.config.name}_option_${name}`];
        if (ctrl) {
          ctrl.setValue(true);
        }
      });
    });
  }

  isRequired(ctrl: AbstractControl<any, any> | null) {
    if (!ctrl || !ctrl.validator) {
      return false;
    }
    const validator = ctrl?.validator({} as AbstractControl);
    return validator && validator['required'];
  }

  get showError() {
    const field = this.group.get(this.config.name);
    return field?.invalid && (field?.touched || field?.dirty || this.checkBoxes.touched || this.checkBoxes.dirty);
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  isChecked(id: string) {
    const currentvalue = this.group.get(this.config.name)?.value;
    return currentvalue && currentvalue.filter((a: SelectOption) => a.id === id)?.length
      ? 'button--primary'
      : 'button-outline button--primary';
  }
}
