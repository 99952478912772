<section class="related-stories">
  <header class="related-stories__header mb-0 mb-md-4">
    <span class="type-body-md fw-bold w-100 related-stories__subtitle">
      {{ subtitle || ('gerelateerde-verhalen-subtitel' | fromDictionary) }}
    </span>
    <h1 class="related-stories__title h2 text-primary text-max-width">{{ title || ('gerelateerde-verhalen-titel' | fromDictionary) }}</h1>
    <!-- <a [routerLink]="['/initiatieven']" class="button button-link button--primary related-stories__more">
      <span>{{ 'gerelateerde-verhalen-alle-initiatieven-link-titel' | fromDictionary }}</span>
      <app-icon anchor="icon-arrow-right"></app-icon>
    </a> -->
  </header>
  <main class="related-stories__main py-2 py-md-4" *ngIf="stories">
    <app-slider [cursorArrow]="false" [total]="stories.length" [config]="sliderConfig">
      <div class="col-12 col-xsm-6 col-sm-6 col-md-6 col-lg-4" *ngFor="let story of stories">
        <app-story-card [story]="story"></app-story-card>
      </div>
    </app-slider>
  </main>
</section>
