import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { IconModule } from '@teamfoster/sdk/icon';
import { RouterLink } from '@angular/router';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';

@Component({
  selector: 'app-participate-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, IconModule, RouterLink, DictionaryNgrxModule],
  templateUrl: './participate-dialog.component.html',
  styleUrls: ['./participate-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipateDialogComponent {
  private dialogRef = inject(MatDialogRef);
}
