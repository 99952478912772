<article class="story-card">
  <a [routerLink]="story.url" class="block-link" [attr.aria-label]="'meer over ' + story.title">
    <app-image
      class="story-card__img"
      [alt]="story.image.caption || story.title"
      [image]="story.image"
      [sizes]="{ md: 4, xl: 3 }"
      [imageSizes]="[300, 500, 800]"
      [width]="700"
      [height]="840"
      [objectFitMode]="true"
    ></app-image>

    <main class="story-card__content p-3 p-md-4">
      <div class="story-card__content-spacing">
        <div class="story-card__meta">
          <ul class="theme-list">
            <li class="theme-list__item" *ngFor="let item of story.themes">
              <button
                tabindex="-1"
                class="chip chip--light chip-icon chip--sm"
                [attr.aria-label]="'categorie ' + item.title"
                [title]="item.title"
              >
                <app-icon [anchor]="item.icon"></app-icon>
              </button>
            </li>
          </ul>

          <span *ngIf="label" class="chip chip--primary chip--label d-none d-sm-inline-flex">{{ label }}</span>
        </div>
        <div class="story-card__content-titles">
          <strong class="fw-bold font-secondary text-secondary lh-base">
            {{ story.initiative?.title || story.initiative?.subtitle }}
          </strong>

          <h2 class="story-card__content-title text-light font-secondary h6 mb-0 mt-1">{{ story.title }}</h2>
          <span class="date text-white mb-2">{{ story.createdOn | date : 'd MMM y' }}</span>

          <span class="chip chip--label chip--tertiary chip--sm mt-2" *ngIf="getDistance() as metres">
            {{ metres / 1000 | number : '1.0-1' }}km
          </span>
        </div>
      </div>
    </main>
  </a>
</article>
