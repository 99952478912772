<mat-dialog-content class="participate-dialog">
  <header class="mb-3 mt-3 text-primary mat-dialog__header d-flex justify-content-between align-items-center flex-column">
    <span class="text-secondary fw-bold">{{ 'doe-mee-dialoog-subtitel' | fromDictionary }}</span>
    <h1 class="h3 text-primary mb-3 font-primary">{{ 'doe-mee-dialoog-titel' | fromDictionary }}</h1>
  </header>
  <button mat-dialog-close class="button button-link button--primary participate-dialog__close">
    <span>Sluiten</span>
    <fstr-icon [anchor]="'icon-cross'"></fstr-icon>
  </button>
  <div class="markdown-content text-center">
    <p>
      {{ 'doe-mee-dialoog-tekst' | fromDictionary }}
    </p>
  </div>
  <div class="d-flex flex-column gap-3 align-items-center">
    <div class="d-flex gap-3 justify-content-center flex-column flex-md-row">
      <a mat-dialog-close [routerLink]="['/', 'initiatieven', 'aanmelden']" class="text-decoration-none">
        <div class="participate-dialog__link p-2 p-md-3">
          <img class="participate-dialog__link__image" src="/assets/gfx/participate-initiative.svg" />
          <span class="fw-bold lead text-primary">{{ 'doe-mee-dialoog-eerste-knop-tekst' | fromDictionary }}</span>
        </div>
      </a>
      <a mat-dialog-close [routerLink]="['/', 'activiteiten', 'aanmelden']" class="text-decoration-none">
        <div class="participate-dialog__link p-2 p-md-3">
          <img class="participate-dialog__link__image" src="/assets/gfx/participate-activiteit.svg" />
          <span class="fw-bold lead text-primary">{{ 'doe-mee-dialoog-tweede-knop-tekst' | fromDictionary }}</span>
        </div>
      </a>
    </div>
    <a mat-dialog-close [routerLink]="['/', 'doemee']">Meer informatie</a>
  </div>
</mat-dialog-content>
