import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { Initiative } from '../../../initiatives/models';

@Component({
  selector: 'app-related-initiatives',
  templateUrl: './related-initiatives.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelatedInitiativesComponent implements OnInit {
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() initiatives: Initiative[] | undefined;

  sliderConfig = {
    showBullets: false,
    showArrows: false,
    type: 'slider',
    bound: true,
    rewind: true,
    perView: 3,
    breakpoints: {
      1200: {
        perView: 3,
      },
      991: {
        perView: 2,
      },
      600: {
        perView: 1,
      },
      390: {
        perView: 1,
      },
    },
  };

  private dict = new FromDictionaryPipe(this.lang);

  constructor(private lang: LanguageService) {}

  ngOnInit(): void {}
}
