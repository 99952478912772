import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on } from '@ngrx/store';
import { Profile, User } from '../../models';

import * as actions from '../actions/auth.action';

export interface AuthState {
  user: Profile | undefined;
  roles?: string[];
  authChecked: boolean;
  authChecking: boolean;
  signedIn: boolean;
  signingIn: boolean;
  SignIngOut: boolean;
  updating: boolean;
  updated: boolean;
  registerSuccess: boolean;
  registering: boolean;
  accountActivating: boolean;
  accountActivated: boolean;
  passwordUpdating: boolean;
  passwordUpdated: boolean;
  error: HttpErrorResponse | null | undefined;
  registerError: HttpErrorResponse | null | undefined;
}

export const initialState: AuthState = {
  user: undefined,
  authChecked: false,
  authChecking: false,
  signedIn: false,
  signingIn: false,
  SignIngOut: false,
  updated: false,
  updating: false,
  registering: false,
  accountActivated: false,
  accountActivating: false,
  passwordUpdating: false,
  passwordUpdated: false,
  registerSuccess: false,
  error: null,
  registerError: null,
};

export const authReducer = createReducer(
  initialState,
  on(actions.CheckAuth, (state: AuthState) => {
    return {
      ...state,
      authChecked: false,
      authChecking: true,
    };
  }),
  on(actions.CheckAuthSuccess, (state: AuthState, { auth }) => {
    return {
      ...state,
      user: auth.user,
      authChecked: true,
      authChecking: false,
      signedIn: auth.isAuth,
      error: null,
    };
  }),
  on(actions.CheckAuthFail, (state: AuthState, { error }) => {
    return {
      ...state,
      user: undefined,
      authChecked: true,
      signedIn: false,
      signingIn: false,
      error,
    };
  }),
  on(actions.SignIn, (state: AuthState, { loginData }) => {
    return {
      ...state,
      authChecked: false,
      signingIn: true,
    };
  }),
  on(actions.SignInSuccess, (state: AuthState, { auth }) => {
    return {
      ...state,
      authChecked: true,
      user: auth.user,
      signedIn: auth.isAuth,
      signingIn: false,
      error: null,
    };
  }),
  on(actions.SignInFail, (state: AuthState, { error }) => {
    return {
      ...state,
      user: undefined,
      authChecked: true,
      signedIn: false,
      signingIn: false,
      error,
    };
  }),
  on(actions.SignOut, (state: AuthState) => {
    return {
      ...state,
      authChecked: false,
      SignIngOut: true,
    };
  }),
  on(actions.SignOutSuccess, (state: AuthState, { result: auth }) => {
    return {
      ...state,
      authChecked: true,
      user: undefined,
      signedIn: false,
      signingIn: false,
      SignIngOut: false,
      error: null,
      registerError: null,
    };
  }),
  on(actions.SignOutFail, (state: AuthState, { error }) => {
    return {
      ...state,
      authChecked: true,
      signedIn: true,
      SignIngOut: false,
      error,
    };
  }),
  on(actions.Register, (state: AuthState) => {
    return {
      ...state,
      registering: true,
      registerSuccess: false,
      registerError: null,
    };
  }),
  // TODO: Swap out following actions:
  // on(actions.RegisterSuccess, (state: AuthState, { result: auth }) => {
  //   return {
  //     ...state,
  //     authChecked: false,
  //     user: undefined,
  //     registering: false,
  //     registerSuccess: true,
  //     registerError: null,
  //   };
  // }),
  on(actions.RegisterSuccess, (state: AuthState, { result: auth }) => {
    return {
      ...state,
      authChecked: true,
      user: auth.user,
      registering: false,
      registerSuccess: true,
      signedIn: auth.isAuth,
      signingIn: false,
      error: null,
      registerError: null,
    };
  }),
  on(actions.RegisterFail, (state: AuthState, { error }) => {
    return {
      ...state,
      authChecked: false,
      registering: false,
      registerSuccess: false,
      registerError: error,
    };
  }),
  on(actions.ActivateAccount, (state: AuthState) => {
    return {
      ...state,
      accountActivating: true,
      accountActivated: false,
      error: null,
    };
  }),
  on(actions.ActivateAccountSuccess, (state: AuthState, { result: any }) => {
    return {
      ...state,
      accountActivated: true,
      accountActivating: false,
      error: null,
    };
  }),
  on(actions.ActivateAccountFail, (state: AuthState, { error }) => {
    return {
      ...state,
      authChecked: false,
      accountActivated: false,
      accountActivating: false,
      error,
    };
  }),
  on(actions.UpdateAccount, (state: AuthState) => {
    return {
      ...state,
      updating: true,
      updated: false,
      error: null,
    };
  }),
  on(actions.UpdateAccountSuccess, (state: AuthState, { profile }) => {
    return {
      ...state,
      user: {
        ...state.user,
        ...profile,
      },
      updated: true,
      updating: false,
      error: null,
    };
  }),
  on(actions.UpdateAccountFail, (state: AuthState, { error }) => {
    return {
      ...state,
      updated: false,
      updating: false,
      error,
    };
  }),

  on(actions.UpdatePassword, (state: AuthState) => {
    return {
      ...state,
      passwordUpdating: true,
      passwordUpdated: false,
      error: null,
    };
  }),
  on(actions.UpdatePasswordSuccess, (state: AuthState, { result: any }) => {
    return {
      ...state,
      passwordUpdated: true,
      passwordUpdating: false,
      error: null,
    };
  }),
  on(actions.UpdatePasswordFail, (state: AuthState, { error }) => {
    return {
      ...state,
      authChecked: false,
      passwordUpdated: false,
      passwordUpdating: false,
      error,
    };
  }),
  on(actions.RemoveErrors, (state: AuthState) => {
    return {
      ...state,
      error: null,
      registerError: null,
    };
  })
);

export const getAuthUser = (state: AuthState) => state.user;
export const getAuthChecked = (state: AuthState) => state.authChecked;
export const getAuthChecking = (state: AuthState) => state.authChecking;
export const getAuthSignedIn = (state: AuthState) => state.signedIn;
export const getAuthSigningIn = (state: AuthState) => state.signingIn;
export const getAuthError = (state: AuthState) => state.error;
export const getUser = (state: AuthState) => state.user;

export const getRegistering = (state: AuthState) => state.registering;
export const getRegisterSuccess = (state: AuthState) => state.registerSuccess;
export const getRegisterError = (state: AuthState) => state.registerError;

export const getAccountActivated = (state: AuthState) => state.accountActivated;
export const getAccountActivating = (state: AuthState) => state.accountActivating;

export const getPasswordUpdated = (state: AuthState) => state.passwordUpdated;
export const getPasswordUpdating = (state: AuthState) => state.passwordUpdating;

export const getAccountUpdated = (state: AuthState) => state.updated;
export const getAccountUpdating = (state: AuthState) => state.updating;
