import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { SetTitle } from '../../../store';
import { Store } from '@ngrx/store';
import { DynamicFormField } from '@teamfoster/sdk/legacy-dynamic-form';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {
  prefix = 'reset-wachtwoord';
  loading = false;
  result: any;
  checkValidation = false;
  private dict = new FromDictionaryPipe(this.lang);

  formFields: DynamicFormField[] = [];

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private lang: LanguageService,
    private store: Store
  ) {}

  ngOnInit() {
    this.formFields = [
      {
        inputType: 'password',
        label: this.dict.transform(`${this.prefix}-formulier-veld-label-nieuw-wachtwoord`),
        name: 'password',
        hint: this.dict.transform('registreren-wachtwoord-hint'),
        validators: [Validators.required],
        autocomplete: 'new-password',
        placeholder: this.dict.transform(`${this.prefix}-placeholder`),
        fieldType: 'input',
        order: 0,
      },
      {
        inputType: 'password',
        label: this.dict.transform(`${this.prefix}-formulier-veld-label-wachtwoord-bevestigen`),
        name: 'confirmPassword',
        validators: [Validators.required],
        autocomplete: 'new-password',
        placeholder: this.dict.transform(`${this.prefix}-formulier-veld-placeholder-wachtwoord-bevestigen`),
        fieldType: 'input',
        order: 0,
      },
    ];
    this.store.dispatch(
      SetTitle({ title: `${this.dict.transform(`${this.prefix}-titel`)} - ${this.dict.transform('meta-titel-suffix')}` })
    );
  }

  async resetPassword(val: any) {
    this.checkValidation = true;
    this.loading = true;
    this.result = await this.auth
      .resetPassword(this.route.snapshot.queryParams['token'], this.route.snapshot.queryParams['uid'], val)
      .toPromise();
    this.loading = false;
    this.cd.detectChanges();
  }

  getErrorMessage(error: { succeeded: boolean; isLockedOut: boolean; isNotAllowed: boolean; requiresTwoFactor: boolean }) {
    console.warn('TODO: custom error messages reset password', error);
    return `${this.prefix}-formulier-error-default`;
  }
}
