import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { Event } from '../../../events/models';

@Component({
  selector: 'app-related-events',
  templateUrl: './related-events.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelatedEventsComponent implements OnInit {
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() compact = false;
  @Input() events: Event[] | undefined;

  sliderConfig = {
    showBullets: false,
    showArrows: false,
    type: 'slider',
    bound: true,
    rewind: true,
    perView: 3,
    breakpoints: {
      1200: {
        perView: 3,
      },
      991: {
        perView: 2,
      },
      600: {
        perView: 2,
      },
      390: {
        perView: 1,
      },
    },
  };

  private dict = new FromDictionaryPipe(this.lang);

  constructor(private lang: LanguageService) {}

  ngOnInit(): void {}
}
