import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as socialActions from '../actions/search-results.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { SearchService } from '../../services';

@Injectable()
export class SearchResultsEffects {
  constructor(private actions$: Actions, private service: SearchService) {}

  LoadSearchResults$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(socialActions.LoadSearchResults),
      switchMap(({ q, patch }) =>
        this.service.Search(q).pipe(
          map(results => socialActions.LoadSearchResultsSuccess({ results, patch })),
          catchError(error => of(socialActions.LoadSearchResultsFail({ error })))
        )
      )
    );
  });
}
