import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromContentCards from '../reducers/content-card.reducer';

export const getContentCardstate = createSelector(
  fromFeature.getContentCardsState,
  (state: fromFeature.ContentCardsState) => state?.contentCards
);

export const getContentCardEntities = createSelector(getContentCardstate, fromContentCards.getContentCardsEntities);

export const getContentCardsLoaded = createSelector(getContentCardstate, fromContentCards.getContentCardsLoaded);
export const getContentCardsLoading = createSelector(getContentCardstate, fromContentCards.getContentCardsLoading);

export const getAllContentCards = createSelector(getContentCardEntities, entities => {
  return Object.keys(entities).map(id => entities[id as unknown as number]);
});
