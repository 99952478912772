import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { getDistance } from 'geolib';
import { SearchResult } from 'src/app/search/models';
import { Initiative } from '../../../initiatives/models';

@Component({
  selector: 'app-initiative-card',
  templateUrl: './initiative-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InitiativeCardComponent {
  @Input() initiative!: Initiative;
  @Input() location!: GeolocationPosition | undefined | null;
  @Input() inverted = false;
  @Input() showIntro = true;
  @Input() label?: string;

  getDistance() {
    if (!this.location || !this.initiative.location?.lat || !this.initiative.location?.lng) {
      return undefined;
    }
    return getDistance(
      [this.initiative.location.lat, this.initiative.location.lng],
      [this.location.coords.latitude, this.location.coords.longitude]
    );
  }
}
