<app-auth-layout [title]="prefix + '-titel' | fromDictionary">
  <ng-container form>
    <fstr-dynamic-form
      [appearance]="'fill'"
      *ngIf="!result || (result && !result.succeeded)"
      [config]="formFields"
      (formSubmit)="resetPassword($event)"
      [showSubmit]="false"
      class="theme-light"
    >
      <div slot="form-actions" class="mt-4">
        <button type="submit" class="button button--primary">
          {{ prefix + '-formulier-verzenden-knoptekst' | fromDictionary }}
          <app-icon class="icon-chevron-right ms-2" anchor="icon-chevron-right"></app-icon>
        </button>
      </div>
    </fstr-dynamic-form>

    <app-alert
      *ngIf="result && !result.succeeded"
      slot="form-alert"
      [status]="'danger'"
      [title]="result.errors ? result.errors[0].description : (prefix + '-melding-onbekende-fout-tekst' | fromDictionary)"
      class="d-block mt-3"
    ></app-alert>

    <ng-container *ngIf="result?.succeeded">
      <app-alert
        [status]="'success'"
        [message]="prefix + '-formulier-succes-tekst' | fromDictionary"
        [title]="prefix + '-formulier-succes-titel' | fromDictionary"
        class="d-block mt-3 mb-3"
      ></app-alert>

      <a class="button button--primary" [routerLink]="['/', 'account', 'inloggen']">
        <span>{{ prefix + '-formulier-succes-knoptekst' | fromDictionary }}</span>
      </a>
    </ng-container>
  </ng-container>
</app-auth-layout>
