import * as fromContentCards from './content-card.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'content-cards';

export interface ContentCardsState {
  contentCards: fromContentCards.ContentCardsState;
}

export const reducers: ActionReducerMap<ContentCardsState> = {
  contentCards: fromContentCards.ContentCardsReducer,
};

export const getContentCardsState = createFeatureSelector<ContentCardsState>(featureKey);

export const metaReducers: MetaReducer<ContentCardsState>[] = !environment.production ? [] : [];
