import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormFileInputComponent } from './components/form-file-input/form-file-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { LegacyDynamicFormModule as DynamicFormModule } from '@teamfoster/sdk/legacy-dynamic-form';
import { FormLocationComponent } from './components/form-location/form-location.component';
import { FormThemesChooserComponent } from './components/form-themes-chooser/form-themes-chooser.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientModule } from '@angular/common/http';
import { FormStepperComponent } from './components/form-stepper/form-stepper.component';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { WysiwygEditorComponent2 } from './components/wysiwyg-editor/wysiwyg-editor.component';
import { QuillModule } from 'ngx-quill';
import { FormDateComponent } from './components/form-date/form-date.component';

// extends dynamic form fields
export const formControls = {
  wysiwyg2: WysiwygEditorComponent2,
  image: FormFileInputComponent,
  location: FormLocationComponent,
  themes: FormThemesChooserComponent,
  date: FormDateComponent,
};

@NgModule({
  declarations: [
    FormFileInputComponent,
    FormLocationComponent,
    FormThemesChooserComponent,
    FormStepperComponent,
    WysiwygEditorComponent2,
    FormDateComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    ReactiveFormsModule,
    DictionaryNgrxModule,
    MatInputModule,
    DynamicFormModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatCheckboxModule,
    TextUtilityModule,
    GoogleMapsModule,
    HttpClientModule,
    MatAutocompleteModule,
    FormsModule,
    QuillModule,
  ],
  exports: [FormStepperComponent],
})
export class FormModule {}
