import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ContentCard } from '../../models';
import { LoadContentCard, getContentCardEntities } from '../../store';
import { Store } from '@ngrx/store';
import { url } from 'inspector';

@Component({
  selector: 'app-content-card',
  templateUrl: './content-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentCardComponent implements OnInit {
  @Input() id!: number;

  store = inject(Store);
  contentCard$?: Observable<ContentCard>;

  ngOnInit(): void {
    // console.log("id in contentcard component", this.id);
    this.store.dispatch(LoadContentCard({ id: this.id }));
    this.contentCard$ = this.store.select(getContentCardEntities).pipe(map(a => a[this.id]));
  }
}
