import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-wave-cta',
  templateUrl: './wave-cta.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaveCtaComponent {
  @Input() firstLine?: string;
  @Input() secondLine?: string;

  @Input() bigTitle?: string;
}
