import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import * as fromStore from './store';
import { VoteComponent } from './components/vote/vote.component';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { CoreModule } from '../core/core.module';

@NgModule({
  declarations: [VoteComponent],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers, {
      metaReducers: fromStore.metaReducers,
    }),
    EffectsModule.forFeature(fromStore.effects),
    DictionaryNgrxModule,
    CoreModule,
  ],
  exports: [VoteComponent],
})
export class LikesModule {}
