<ng-container [formGroup]="group">
  <!--<mat-form-field>-->
  <div
    class="custom-field"
    *ngIf="group.get(config.name) as field"
    [class.has--label]="!!config.label"
    [class.has--sub-label]="!!config.subLabel"
    [ngClass]="{
      'custom-field--invalid': showError
    }"
  >
    <input
      class="theme-chooser__input"
      matInput
      [formControlName]="config.name"
      [ngClass]="{
        'custom-field--invalid': showError
      }"
      [hidden]="true"
    />

    <span class="label">
      {{ config.label }}
      <span class="mat-form-field-required-marker" *ngIf="isRequired(group.get(config.name))">*</span>
    </span>

    <ul class="form-themes-list mt-1" [formGroup]="checkBoxes">
      <li *ngFor="let option of config.selectOptions; let i = index">
        <label class="button theme-button" [ngClass]="isChecked(option.id)" [for]="option.id">
          <app-icon class="theme-button__chip" *ngIf="option.icon" [anchor]="option.icon"></app-icon>

          <input type="checkbox" [id]="option.id" [value]="option.id" [formControlName]="config.name + '_option_' + option.id" />
          <span>{{ option.title }}</span>
        </label>
      </li>
    </ul>

    <div class="custom-field__error-wrapper">
      <mat-error *ngIf="showError">
        {{ field | formError : config.customValidationMessages }}
      </mat-error>
    </div>
  </div>
  <!--</mat-form-field>-->
</ng-container>
