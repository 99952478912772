<header class="header section-inset-t" [class.header--dark]="themeDark">
  <div class="container header__inner">
    <div class="row">
      <div class="col-md-6 header__content d-flex align-items-center">
        <div class="text-max-width-xxs mt-2 mt-lg-5 pb-3 pb-lg-5">
          <h2 class="text-primary" *ngIf="!logoAsTitle && title">
            {{ title }}
            <app-icon anchor="icon-squiggle" class="heading-squiggle"></app-icon>
          </h2>

          <img
            class="header__logo-title"
            *ngIf="logoAsTitle"
            [loading]="'eager'"
            src="/assets/gfx/onze-ijssel-logo-inverted.svg"
            [alt]="'logo-alt-tekst' | fromDictionary"
          />

          <div
            *ngIf="header.intro"
            class="lead mt-4"
            [class.fw-normal]="logoAsTitle"
            fstrDynamicContent
            [innerHTML]="header.intro || '' | resizeHtmlImages | stripEmbed : cookiePreferences ?? undefined | safe"
          ></div>
        </div>
      </div>
      <div class="col-md-6 header__figure" *ngIf="header.image && header.image.url">
        <article class="pull-e header__figure__inner" [class.has--overlay]="(header.button && header.button.url) || header.title">
          <app-image
            [imageSizes]="[300, 500, 800, 1400]"
            [width]="700"
            [height]="500"
            [alt]="header.image.alt ?? header.title"
            [image]="header.image"
            [loading]="'eager'"
            [sizes]="{ xs: 12, md: 6 }"
            [objectFitMode]="true"
          ></app-image>

          <header class="header-image-overlay" *ngIf="(header.button && header.button.url) || header.title">
            <div class="header-image-overlay__content">
              <strong class="text-secondary w-100 d-inline-block mb-1" *ngIf="header.subtitle">{{ header.subtitle }}</strong>
              <h2 class="text-white h6 font-secondary fw-bold lh-sm" *ngIf="header.title">{{ header.title }}</h2>
            </div>

            <a class="card-link" [routerLink]="header.button.routerLink" *ngIf="header.button && header.button.routerLink?.length">
              <strong class="card-link__label">{{ header.button.text }}</strong>

              <div class="button button--lg button-icon button--tertiary">
                <app-icon anchor="icon-arrow-right"></app-icon>
              </div>
            </a>
            <a class="card-link" [href]="header.button.url" *ngIf="header.button && header.button.url && !header.button.routerLink?.length">
              <strong class="card-link__label">{{ header.button.text }}</strong>

              <div class="button button--lg button-icon button--tertiary">
                <app-icon anchor="icon-arrow-right"></app-icon>
              </div>
            </a>
          </header>
        </article>
      </div>

      <app-bg-wave
        *ngIf="hasWave"
        class="wave-front"
        [autoPlay]="true"
        [startPos]="'12s'"
        [bgColor]="themeDark ? 'var(--bs-primary)' : 'var(--bs-light)'"
        [strokeColor]="'transparent'"
      ></app-bg-wave>
    </div>
  </div>

  <app-bg-wave
    *ngIf="hasWave"
    class="wave-bottom"
    [autoPlay]="true"
    [startPos]="'12s'"
    [bgColor]="'var(--bs-light)'"
    [strokeColor]="'transparent'"
  ></app-bg-wave>
</header>

<!--<pre>
  {{ header | json }}
</pre>-->
