import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LandingPage } from 'src/app/landing-pages/models';

@Component({
  selector: 'app-page-card',
  templateUrl: './page-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageCardComponent {
  @Input() page!: LandingPage;
}
